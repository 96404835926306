import { defaultFilters } from '@/data/defaulFilters.js';
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';

export default {
  computed: {
    ...mapGetters({
      workflowsList: 'workflows/workflowsList',
      labelsList: 'labels/labelsList',
    }),
  },
  methods: {
    getFiltersData () {
      const result = [];
      const labelsCategory = {
        category: {
          name: 'Labels',
          _id: 'labels',
          icon: 'bx-label',
        },
        items: this.labelsList,
      };
      const statisticsCategory = {
        category: {
          name: 'Statistics',
          _id: 'statistics',
          icon: 'bx-bar-chart',
        },
        items: [
          { _id: 'with_opened_email', name: 'Email open rate' },
          { _id: 'with_bounced_email', name: 'Bounce rate' },
        ],
      };
      const campaignsCategory = {
        category: {
          name: 'Campaigns',
          _id: 'workflows',
          icon: 'bx-briefcase',
        },
        items: [
          {
            name: 'show dialogs outside campaigns',
            _id: 'outside_campaigns',
          },
          ...this.workflowsList,
        ],
      };
      const defaultCategories = cloneDeep(defaultFilters);
      return result.concat(
        campaignsCategory,
        statisticsCategory,
        defaultCategories,
        labelsCategory
      );
    },
  },
};
