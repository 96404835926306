<template>
  <div class="flex-row flex-gap-4">
    <default-button
      data-excluded="responses"
      icon-name="bx-reply"
      title-color="color_tertiary"
      hovered-title-color="hovered_primary"
      form="square"
      size="m"
      :has-paddings="false"
      @action="$emit('open-responses')"
    />
    <default-button
      data-excluded="emojies"
      icon-name="bx-smile"
      title-color="color_tertiary"
      hovered-title-color="hovered_primary"
      form="square"
      size="m"
      :has-paddings="false"
      @action="$emit('open-emojies')"
    />
    <default-button
      title="Send"
      color="primary"
      form="rectangle"
      size="m"
      :is-disabled="!canSend"
      @action="$emit('send')"
    />
    <default-button
      data-excluded="schedule"
      icon-name="bx-calendar"
      color="primary"
      form="square"
      size="m"
      :is-disabled="!canSend"
      @action="$emit('schedule')"
    />
  </div>
</template>
<script>
  import DefaultButton from '@/components/base/uiKit/DefaultButton';

  export default {
    name: 'ButtonsResponser',
    components: {
      DefaultButton,
    },
    props: {
      canSend: {
        type: Boolean,
        required: true,
      },
    },
  };
</script>
