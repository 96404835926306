<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="templates-menu">
    <div
      v-if="template?._id"
      class="templates-menu__item"
    >
      <span class="templates-menu__text text_m">
        {{ template.name }}
      </span>
      <span
        v-if="template.subject"
        class="templates-menu__text text_s color_secondary" 
      >
        {{ template.subject }}
      </span>
      <span class="templates-menu__text text_xs color_secondary">
        {{ template.text_template || 'There is no text' }}
      </span>
      <default-button
        class="templates-menu__delete margin-left"
        title="Unlink template"
        color="none"
        icon-name="bx-unlink"
        title-color="link"
        size="s"
        @action="$emit('unlink-template')"
      />
    </div>
    <div
      v-else
      class="templates-menu__add"
      @click="$emit('choose-template')"
    >
      <default-button
        class="templates-menu__delete"
        title=" Add Template"
        color="none"
        icon-name="bx-plus"
        title-color="link"
        size="s"
        @action="$emit('unlink-template')"
      />
    </div>
  </div>
</template>
<script>
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  export default {
    name: 'TemplatesMenu',
    components: {
      DefaultButton,
    },
    props: {
      template: {
        type: Object,
        default: null,
      },
    },
  };
</script>
<style lang="scss">
.templates-menu {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  &__add {
    width: 250px;
    min-height: 68px;
    border: $border-dashed;
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    cursor: pointer;
    color: var(--primary-cl);
    transition: 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }
  &__item {
    min-width: 250px;
    max-width: 250px;
    min-height: 68px;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(10, 32, 51, 0.12);
    display: flex;
    flex-flow: column;
    gap: 4px;
  }
 &__delete {
   display: flex;
   align-items: center;
   gap: 4px;
 }
  &__text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
    white-space: nowrap;
  }
}
</style>
