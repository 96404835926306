const resizableMixin = {
  data () {
    return {
      resizeBlock: {
        ref: 'resizable',
        width: 0,
      },
    };
  },
  mounted () {
    this.initResize();
  },
  beforeDestroy () {
    this.$refs[this.resizeBlock.ref].removeEventListener('mousedown', this.resize);
  },
  methods: {
    initResize () {
      if (!this.$refs[this.resizeBlock.ref]) {
        return;
      }
      const resizeBlock = localStorage.getItem('resizeBlock');
      if (resizeBlock) {
        this.resizeBlock = JSON.parse(resizeBlock);
      }
      this.$refs[this.resizeBlock.ref].addEventListener('mousedown', this.resize);
    },
    resize () {
      const $parent = this.$refs[this.resizeBlock.ref].parentNode;
      const parentCoords = $parent.getBoundingClientRect();

      document.onmousemove = e => {
        const offset = e.pageX - parentCoords.right;
        const resultedWidth = parentCoords.width + offset;
        $parent.style.width = resultedWidth + 'px';
        this.resizeBlock.width = resultedWidth;
      };

      document.onmouseup = () => {
        document.onmousemove = null;
        document.onmouseup = null;
        localStorage.setItem('resizeBlock', JSON.stringify(this.resizeBlock));
      };
    },
  },
};

export default resizableMixin;
