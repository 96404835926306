<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="row between">
    <div
      v-if="status === 'process'"
    >
      <div class="row">
        <i class="bx bxs-video bx-md color_danger" />
        <span class="text text_m color_secondary">{{ recordingTime.toFixed(1) }}</span>
      </div>
      <span class="text text_m">Recording...</span>
    </div>
    <div />
    <div
      v-if="['paused', 'process'].includes(status)"
      class="row"
    >
      <button
        class="primary-btn_gray primary-btn_size_s"
        @click="status === 'process' ? $emit('pause-video') : $emit('resume-video')"
      >
        <i
          :class="['bx bx-md', status === 'process' ? 'bx-pause' : 'bx-play']"
        />
        {{ status === 'process' ? 'Pause' : 'Resume' }}
      </button>
      <button
        class="primary-btn_blue primary-btn_icon primary-btn_size_s"
        @click="$emit('stop-video')"
      >
        <i class="bx bx-stop bx-md" />
        Stop recording
      </button>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'EditorRecordButtons',
    props: {
      status: {
        type: String,
        default: '',
      },
      recordingTime: {
        type: Number,
        default: null,
      },
    },
  };
</script>
