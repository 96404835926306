import { computed } from 'vue';

const { useStore } = require('@/store');

const useConfig = () => {
  const store = useStore();
  const $config = computed(() => store.getters['user/user']?.config);
  return { $config };
};
export { useConfig };
