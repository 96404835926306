<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<!-- eslint-disable vue/no-v-html !-->
<template>
  <div class="preview-email">
    <div class="preview-email__header">
      <span><b>To: </b> example@email.com</span>
      <span class="mt-1"><b>Subject: </b> {{ subject }}</span>
    </div>
    <div
      class="preview-email__content ql-container ql-snow ql-editor"
      v-html="message"
    />
  </div>
</template>
<script>
  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';

  export default {
    name: 'PreviewEmailMessage',
    props: {
      message: {
        type: String,
        default: '',
      },
      subject: {
        type: String,
        default: '',
      },
    },
  };
</script>
<style scoped lang="scss">
  .preview-email {
    width: 100%;
    &__header {
      display: flex;
      flex-direction: column;
    }

    &__content {
      font-size: 14px;
      width: 100%;
      border-top: 1px solid #e0e2e8 !important;
      border-bottom: 1px solid #e0e2e8 !important;
      margin-top: 16px;
      padding: 16px 0;
      min-height: 300px;
      height: fit-content;
    }
  }
</style>
