<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="workspace-appearance">
    <div class="workspace-appearance__buttons">
      <button
        class="workspace-appearance__button"
        @click="isHorizontal = false"
      >
        <img
          src="~@/assets/VerticalSidebar.svg"
          :class="[
            'workspace-appearance__img',
            {'workspace-appearance__img_active':!isHorizontal}
          ]"
        >
        <span class="text_xs workspace-appearance__button-text">Vertical</span>
      </button>

      <button
        class="workspace-appearance__button"
        @click="isHorizontal = true"
      >
        <img
          src="~@/assets/HorizontalSidebar.svg"
          :class="[
            'workspace-appearance__img',
            {'workspace-appearance__img_active': isHorizontal}
          ]"
        >
        <span class="text_xs workspace-appearance__button-text">Horizontal</span>
      </button>
    </div>
    <div class="flex flex-justify-content-end mt-16">
      <default-button
        title="Save changes"
        color="primary"
        form="rectangle"
        size="m"
        @action="saveChange"
      />
    </div>
  </div>
</template>

<script>
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import { mapGetters } from 'vuex';

  export default {
    name: 'WorkspaceAppearance',
    components: {
      DefaultButton,
    },

    data () {
      return {
        isHorizontal: '',
      };
    },
    computed: {
      ...mapGetters({
        horizontal: 'view/horizontal',
      }),
    },
    mounted () {
      this.isHorizontal = this.horizontal;
    },

    methods: {
      saveChange () {
        this.$store.dispatch('view/UPDATE_VIEW', { horizontal: this.isHorizontal });
      },
    },
  };
</script>

<style lang="scss">
.workspace-appearance {
  &__buttons {
    flex-wrap: wrap;
    display: flex;
    gap: 8px;
  }

  &__button {
    cursor: pointer;
    display: block;
    margin: 0;
    padding: 0;
    background: none;
    display: flex;
    border: none;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    color: var(--text-color);
  }
  &__img{
    border: 1px solid;
    border-radius: 8px;
    border-color: var(--neutral-300);
    &_active{
      border-color: var(--primary-cl);
    }
  }
}
</style>
