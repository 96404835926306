<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <article
    :class="[
      'video-card',
      $route.name === 'videos' ? 'video-card_column' : 'video-card_row' // для разных роутов свой стиль
    ]"
  >
    <video
      class="video-card__video"
      :src="video.video_src"
    />
    <div class="video-card__info">
      <p class="text text_s">
        {{ video.name }}
      </p>
      <p class="text text_xs color_tertiary">
        {{ video.created_at | moment("DD MMM Y") }}
      </p>
    </div>
    <default-tooltip
      class="video-card__actions"
      data-closable="videoTooltip"
      list-position="right"
      :items="setTooltipList"
      :show.sync="showTooltip"
      @choose="$emit($event.value, video)"
    >
      <i class="bx bx-dots-vertical-rounded card__icon" />
    </default-tooltip>
  </article>
</template>

<script>
  import DefaultTooltip from '@/components/base/uiKit/DefaultTooltip';
  export default {
    name: 'VideoItem',
    components: {
      DefaultTooltip,
    },
    props: {
      video: {
        type: Object,
        default: () => {
        },
      },
      mode: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        showTooltip: false,
        dataTooltip: [
          { name: 'Edit', mode: ['templates'], value: 'edit' },
          { name: 'Delete', mode: ['templates'], value: 'delete' },
          { name: 'Insert placeholder', mode: ['insert'], value: 'insert' },
          { name: 'Discard', mode: ['discard'], value: 'discard' },
        ],
      };
    },
    computed: {
      setTooltipList () {
        return this.dataTooltip.filter(item => item.mode.includes(this.mode));
      },
    },
  };
</script>
<style lang="scss">
.video-card {
  row-gap: 8px;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 1px 1px 4px var(--tooltip-shadow);
  background: var(--background-color);
  cursor: pointer;
  transition: 0.5s;

  &_column {
    display: grid;
    gap: 16px;
    min-height: 125px;
    grid-template-areas:
      'info info'
      'video actions';
  }

  &_row {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &:hover {
    box-shadow: 0 0 12px var(--tooltip-shadow);
  }

  &__info {
    grid-area: info;
  }

  &__video {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
  }

  &__actions {
    grid-area: actions;
    margin-left: auto;
    align-self: center;
  }
}
</style>
