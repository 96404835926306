<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    class="crm-item"
    @click.stop="$emit('select-item', item)"
  >
    <div class="crm-item__item-section">
      <div class="crm-item__checkbox">
        <checkbox
          :is-active="chosenItemsIds.includes(item._id)"
          @select-checkbox="$emit('select-item', item)"
        />
      </div>
      <default-avatar
        :photo-link="item.avatar"
        status-color="green"
        size="xs"
      />
      <div class="crm-item__item-info">
        <span class="crm-item__text crm-item__text_hidden text_s">{{ item.full_name || item.name }}</span>
        <span class="crm-item__text crm-item__text_hidden text_xs">{{ item.email || 'No Email' }}</span>
      </div>
    </div>
    <div class="crm-item__item-section">
      <div class="crm-item__item-info">
        <span class="crm-item__text text_xs color_secondary"><i class="bx bx-globe" />Company</span>
        <span class="crm-item__text text_xs">{{ item?.crm_company?.name || item?.company_name || item?.local_company_name || 'No Company' }}</span>
      </div>
    </div>
    <div class="crm-item__item-section">
      <div class="crm-item__item-info">
        <span class="crm-item__text text_xs color_secondary"><i class="bx bxs-phone" />Phone</span>
        <span class="crm-item__text text_xs">{{ item.phone || 'No Phone' }}</span>
      </div>
    </div>
    <div class="crm-item__item-section">
      <div class="crm-item__item-info">
        <span class="crm-item__text text_xs color_secondary"><i class="bx bx-user" />Owner</span>
        <span class="crm-item__text text_xs">{{ item?.excess_data?.owner_name || 'No Owner' }}</span>
      </div>
    </div>
    <div
      class="crm-item__item-section"
      @click.stop="() => {}"
    >
      <a
        :href="linkToProspect"
        target="_black"
      >
        <i class="crm-item__text text_s bx bx-link-external" />
      </a>
    </div>
  </div>
</template>

<script>
  import DefaultAvatar from '@/components/base/uiKit/DefaultAvatar';
  import Checkbox from '@/components/base/Checkbox.vue';
  import { mapGetters } from 'vuex';

  export default {
    name: 'CrmProspectItem',
    components: {
      DefaultAvatar,
      Checkbox,
    },
    props: {
      item: {
        type: Object,
        default: () => {},
      },
      chosenProspects: {
        type: Array,
        default: () => [],
      },
      chosenCompanies: {
        type: Array,
        default: () => [],
      },
      isCompanies: {
        type: Boolean,
        default: false,
      },
      crm: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
      };
    },

    computed: {
      ...mapGetters({
        credentialsList: 'credentials/credentialsList',
      }),
      chosenItemsIds () {
        if (this.isCompanies) {
          return this.chosenCompanies.slice();
        }
        return this.chosenProspects.slice();
      },
      linkToProspect () {
        if (!this.isCompanies) {
          if (this.crm === 'default') {
            const credWithProfile = this.credentialsList.find(cred => cred._id === this.item?.local_credentials || this.item._id);
            return `/inbox/${this.item._id}?tag=all&selectedCred=${credWithProfile._id}`;
          } else if (this.crm === 'pipedrive') {
            return `https://${this.item.crm_space?.domain}.pipedrive.com/person/${this.item._id}`;
          } else if (this.crm === 'hubspot') {
            return `https://app.hubspot.com/contacts/${this.item.crm_space?.external_id}/contact/${this.item._id}`;
          }
        } else {
          if (this.crm === 'hubspot') {
            return `https://app.hubspot.com/contacts/${this.item.crm_space?.external_id}/company/${this.item._id}`;
          } else if (this.crm === 'pipedrive') {
            return `https://${this.item.crm_space?.domain}.pipedrive.com/organization/${this.item._id}`;
          }
        }
        return '';
      },
    },
    watch: {
      chosenItemsIds () {
      },
    },
    async created () {

    },
    mounted () {
    },
    methods: {
    },
  };
</script>

<style lang="scss">
.crm-item {
     display: flex;
      justify-content: space-between;
      box-shadow: 0px 2px 4px rgba(10, 32, 51, 0.06), 0px 0px 8px rgba(10, 32, 51, 0.05);
      border-radius: 8px;
      padding: 8px 12px;
      cursor: pointer;
      &:hover {
        background: var(--hover-secondary);
      }
       &__checkbox {
    align-self: center;
  }
    &__item {
      display: flex;
    }
    &__item-section {
      display: flex;
      align-items: center;
      min-width: 180px;
      flex: 1;
      gap: 8px;
    }
    &__item-info {
      display: flex;
      flex-flow: column;
      width: 100%;
    }
    &__text {
      display: flex;
      align-items: center;
      gap: 4px;
      &_hidden {
        display: block;
        white-space: nowrap;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
}
</style>
