import { render, staticRenderFns } from "./EditorStartButtons.vue?vue&type=template&id=3a835b01&scoped=true&"
import script from "./EditorStartButtons.vue?vue&type=script&lang=js&"
export * from "./EditorStartButtons.vue?vue&type=script&lang=js&"
import style0 from "./EditorStartButtons.vue?vue&type=style&index=0&id=3a835b01&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../temp_divo-ui/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a835b01",
  null
  
)

export default component.exports