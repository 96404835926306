<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="placeholders-menu">
    <div class="placeholders-menu__container">
      <template v-for="column in templates">
        <div
          v-if="column._id !== 'lyne'"
          :key="column._id"
          class="placeholders-menu__column"
        >
          <span class="placeholders-menu__text color_tertiary text_s">{{ column.name }}</span>
          <div
            class="placeholders-menu__items-container"
            :class="{'placeholders-menu__items-container_column': column._id === 'personal'}"
          >
            <template v-for="template in column.data">
              <span
                v-if="!template.disabled"
                :key="template.value"
                class="placeholders-menu__item placeholders-menu__text color_secondary text_s"
                @click="addTemplateToArea(template)"
              >
                <i class="bx bx-code-curly" />
                {{ template.value }}
              </span>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import { templatesMixin } from '@/mixins/templatesMixin';

  import { mapGetters } from 'vuex';

  export default {
    name: 'PlaceholdersMenu',
    components: {
    },
    mixins: [templatesMixin],
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
      };
    },
    computed: {
      ...mapGetters({
        user: 'user/user',
      }),
    },
    methods: {
      addTemplateToArea (template) {
        if (!template.disabled && !this.disabled) {
          const templateStr = template.fallback ? `{${template.value}:FALLBACK}` : `{${template.value}}`;
          this.$emit('add-template-to-area', templateStr, template.space);
        }
      },
    },
  };
</script>
<style lang="scss">
.placeholders-menu {
  display: flex;
flex-flow: column;
  gap: 8px;
  &__container {
  display: flex;
    gap: 32px;
    flex-wrap: wrap;

  }
  &__column {
    display: flex;
    flex-flow: column;
    gap: 8px;
    //flex: 1;
  }
  &__items-container {
    display: grid;
    gap: 8px;
    &_column {
      grid-template-columns: 1fr auto;
    }
  }
  &__item {
    padding: 4px 8px;
    border-radius: 8px;
    cursor: pointer;
    background: var(--neutral-50);
    width: min-content;
    &:hover {
      opacity: 0.7;
    }
  }
  &__text {
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1;
  }
}
</style>
