<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <portal to="body-end-2">
    <modal
      :key="name"
      :name="name"
      :width="width"
      height="auto"
      classes="component"
      :adaptive="true"
      @closed="handleClose"
    >
      <div class="modal">
        <div>
          {{ title }}
        </div>
        <p class="modal__small-text text">
          {{ text }}
        </p>
        <div class="modal__buttons">
          <div
            class="primary-btn primary-btn_gray"
            @click="discardChanges"
          >
            <span class="text">{{ cancelText }}</span>
          </div>
          <div
            class="primary-btn"
            @click="saveChanges"
          >
            <span class="text"> {{ primaryText }} </span>
          </div>
        </div>
      </div>
    </modal>
  </portal>
</template>

<script>

  export default {
    name: 'ConfirmChangesModal',

    props: {
      show: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: 'You have unsaved changes',
      },
      text: {
        type: String,
        default: 'Would you like to save changes you made to selected workflow?',
      },
      cancelText: {
        type: String,
        default: 'Discard Changes',
      },
      primaryText: {
        type: String,
        default: 'Save Changes',
      },
      name: {
        type: String,
        default: 'confirmChangesModal',
      },
      width: {
        type: String,
        default: '550px',
      },
      stay: {
        type: Boolean,
        default: false,
      },
      next: {
        type: Function,
        default: () => {
          this.$router.push('/');
        },
      },
    },
    watch: {
      show (show) {
        show ? this.$modal.show(this.name) : this.$modal.hide(this.name);
      },
    },
    methods: {
      discardChanges () {
        this.$emit('update:show', false);
        this.next();
      },
      saveChanges () {
        this.$emit('save-changes');
        this.$emit('update:show', false);
        if (!this.stay) {
          this.next();
        }
      },
      handleClose () {
        this.$emit('update:show', false);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal {
    padding: 5%;
    display: grid;
    justify-items: center;
    background-color: #F1F3F4;
    height: 100%;
    border-radius: 15px;
    color: black;
    font-size:  1.7rem;
    font-weight: 600;
    grid-row-gap: 43px;

    &__small-text {
      font-size:  1.33rem;
      color: #5F5F5F;
      font-weight: normal;
    }

    &__buttons {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 12px;
      white-space: nowrap;
    }
  }

  .primary-btn {
    width: 100%;
    height: 40px;
    font-size:  1.41rem;
  }
</style>
