<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <portal to="body-end">
    <modal
      :key="key"
      name="continueModal"
      width="800px"
      height="auto"
      :adaptive="true"
      :show.sync="showLocal"
      @closed="handleClose"
    >
      <div class="modal">
        <div class="modal__header mb-3">
          <p class="modal__title">
            Resume Campaign
          </p>
          <x-icon
            size="1.1x"
            class="modal__close"
            @click="handleClose"
          />
        </div>
        <p
          v-if="showSteps"
          class="continue-modal__subtitle info-title mb-2 mt-1"
        >
          Select step to continue from:
        </p>
        <div class="continue-modal__steps mb-3">
          <continue-step
            v-for="(action, i) in steps"
            :key="i"
            :action="action"
            :index="i"
            :active="i === chosenIndex"
            :dissable="action.done"
            @click.native="choose(i)"
          />
        </div>
        <div
          class="modal__buttons"
        >
          <p
            class="primary-btn"
            @click="resume"
          >
            Resume for {{ name }}
          </p>
          <p
            :class="['primary-btn  primary-btn_gray']"
            @click="handleClose"
          >
            Cancel
          </p>
        </div>
      </div>
    </modal>
  </portal>
</template>
<script>
  import { XIcon } from 'vue-feather-icons';
  import ContinueStep from '@/modals/ContinueModal/ContinueStep.vue';
  export default {
    name: 'ContinueModal',
    components: { XIcon, ContinueStep },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      steps: {
        type: Array,
        default: null,
      },
      showSteps: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        default: '',
      },
      localKey: {
        type: String,
        default: 'continueModal',
      },
    },
    data () {
      return {
        showLocal: this.show,
        chosenIndex: 0,
        key: this.localKey,
      };
    },
    watch: {
      show (show) {
        show
          ? this.$modal.show('continueModal')
          : this.$modal.hide('continueModal');
        if (!show) {
          this.key = `${this.key} ${new Date().getTime()}`;
        }
      },

      // name () {
      //   this.modalProps.primaryButtonText = `Resume for ${this.name}`;
      // },
    },
    methods: {
      handleClose () {
        this.$emit('update:show', false);
      },
      choose (index) {
        if (!this.steps[index].done) {
          this.chosenIndex = index;
        }
      },
      resume () {
        this.$emit('resume', this.chosenIndex);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .continue-modal {
    &__steps {
      overflow: auto;
      display: grid;
      grid-template-columns: minmax(150px, 1fr) minmax(150px, 1fr);
      row-gap: 15px;
      column-gap: 15px;
      padding: 8px;
      overflow: auto;
      max-height: calc(100vh - 400px);
    }
    &__subtitle{
      font-size:  1.33rem;
    }
  }
</style>
<style lang="scss">
.continue-modal{
  .modal__header{
    margin-bottom: 0px;
  }
}
</style>
