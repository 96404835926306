<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    v-show="showSaved"
    class="saved-responses"
  >
    <div
      v-show="!showcreate"
      class="responses"
    >
      <div class="responses__header">
        <p class="responses__name">
          Saved Replies
        </p>
      </div>
      <div class="responses__all">
        <saved-response
          v-for="(response, i) in responses"
          :id="response._id"
          :key="response._id"
          :name="response.name"
          :text-template="response.text_template"
          :response="response"
          :tabindex="i"
          :class="i === activeResponseCount ? 'response_active' : ''"
          @edit="edit"
          @delete="deleteResponse"
          @click.native="insert(response.text_template)"
        />
      </div>
      <div
        class="responses__showcreate primary-cl"
        @click="openCreate"
      >
        <plus-icon size="1.3x" />
        <p class="responses__showcreate-text">
          Add Replies
        </p>
      </div>
    </div>
    <saved-responses-modal
      :id="responseToEdit._id"
      :show.sync="showcreate"
      :response="responseToEdit"
      :title="title"
      :primary-text="primaryText"
      @send="save"
    />
  </div>
</template>
<script>
  import SavedResponse from './SavedResponse.vue';
  import { PlusIcon } from 'vue-feather-icons';
  import { mapGetters } from 'vuex';

  import {
    getResponses,
    deleteResponse,
    createResponse,
    updateResponse
  } from '@/api/savedResponsesMethods';
  import { addTemplateMixin } from '@/mixins/addTemplateMixin.js';
  import SavedResponsesModal from './SavedResponsesModal.vue';
  import { templatesMixin } from '@/mixins/templatesMixin';

  export default {
    name: 'SavedResponses',
    components: { SavedResponse, PlusIcon, SavedResponsesModal },
    mixins: [addTemplateMixin, templatesMixin],
    props: {
      showSaved: {
        type: Boolean,
        default: false,
      },
      profile: {
        type: Object,
        default: null,
      },
    },
    data () {
      return {
        responses: [],
        name: '',
        activeResponseCount: 0,
        textTempalte: '',
        showcreate: false,
        responseToEdit: {
          name: '',
          text_templat: '',
        },
        type: 'create',
        error: {
          name: false,
          textTempalte: false,
        },
      };
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
      title () {
        switch (this.type) {
        case 'edit':
          return 'Edit reply';
        default:
          return 'Add reply';
        }
      },
      primaryText () {
        switch (this.type) {
        case 'edit':
          return 'Save';
        default:
          return 'Add Saved Reply';
        }
      },
    },
    watch: {
      showSaved () {
        if (!this.showSaved) {
          this.activeResponseCount = 0;
        }
      },
    },

    async mounted () {
      await this.fetchResponses();
    },

    methods: {
      parse (string) {
        const templateItems = {
          first_name: this.profile.first_name || '',
          last_name: this.profile.last_name || '',
          location: this.profile.location || 'your city',
          company_name: this.profile.company_name || 'your company',
          years_in_company: this.profile.years_in_company || 'a few',
          total_career_positions_count: this.profile.total_career_positions_count || 'a few',
          total_years_in_career: this.profile.total_years_in_career || 'a few',
          college_name: this.profile.college_name || 'your college',
          occupation: this.profile.occupation || 'your occupation',
        };
        const words = string.split(/(?=[{}])/);
        words.map((word, index) => {
          if (
            index !== words.length - 1 &&
            word.charAt(0) === '{' &&
            words[index + 1].charAt(0) === '}'
          ) {
            word = word + '}';
            const fits = !(word.includes('FALLBACK')) && this.templatesRegexps.some((regexp) => regexp.test(word));
            if (fits) {
              const replaceTo = word.split(':')[1] ? word.split(':')[1].slice(0, -1) : templateItems[word.replace('}', '').replace('{', '')];
              string = string.replace(word, replaceTo);
            }
          }
          return word;
        });
        return string.replace(/\r\n/g, '\n');
      },
      openCreate () {
        this.responseToEdit = {
          name: '',
          text_templat: '',
        };
        this.showcreate = true;
        this.type = 'create';
      },
      edit (response) {
        this.responseToEdit = response;
        this.showcreate = true;
        this.type = 'edit';
      },
      async fetchResponses () {
        try {
          this.responses = await getResponses();
        } catch (e) {
        }
      },
      async deleteResponse (id) {
        try {
          await deleteResponse('', id);
          this.fetchResponses();
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      insert (text) {
        this.$emit('insert', this.parse(text));
      },
      async save (name, textTempalte, id = '') {
        try {
          if (this.type === 'create') {
            await createResponse(
              this.selectedCredential._id,
              name,
              textTempalte
            );
          } else {
            await updateResponse(
              this.selectedCredential._id,
              id,
              name,
              textTempalte
            );
          }
          this.$noty.success('Reply is successfully updated');
          this.responseToEdit = {
            name: '',
            text_templat: '',
          };
          await this.fetchResponses();
          this.showcreate = false;
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .saved-responses {
    background: var(--message-test);
    box-shadow: 1px 1px 4px var(--tooltip-shadow);
    padding: 8px;
    border-radius: 8px;
    width: 100%;
    color: var(--text-color);
  }
  .responses {
    max-height: 450px;
    min-height: 200px;
    display: flex;
    flex-flow: column;
    &__header {
      font-size:  1.5rem;
      text-align: left;
      display: flex;
      padding: 0px 10px;
      margin-bottom: 17px;
    }
    &__all {
      overflow-y: auto;
    }
    &__showcreate {
      border-radius: 6px;
      display: flex;
      flex-flow: nowrap;
      column-gap: 12px;
      padding: 12px 0px;
      transition: 0.3s;
      align-items: center;
      font-size:  1.41rem;
      justify-content: center;
      cursor: pointer;
      margin-top: auto;
      &:hover {
        background: var(--active-bg-cl);
      }
    }
  }
  .create-response {
    padding: 16px;
    &__name {
      font-size:  1.7rem;
      font-weight: 400;
      line-height: 1.2;
      margin-bottom: 8px;
    }
    &__input {
      width: 100%;
      border-radius: 12px;
      background-color: #fff;
      padding: 4px 10px;
      border: 1px solid #e1e7f1;
      min-height: 34px;
      display: flex;
      align-items: center;
      &:focus {
        border-color: #74b9ff;
      }
      &_error {
        border-color: #dc3545;
      }
    }
    &__block {
      margin-top: 8px;
      margin-bottom: 20px;
      display: flex;
      flex-flow: column;
      row-gap: 10px;
    }
    &__buttons {
      justify-content: space-evenly;
      display: flex;
      flex-flow: row nowrap;
    }
    &__header {
      color: rgb(33, 37, 41);
      font-size:  1.33rem;
    }
  }
</style>
