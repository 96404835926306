<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="buttons-deal-status">
    <default-button
      v-if="chosenStatus === 'open'"
      size="m"
      :title="'Lost'"
      :color="'danger'"
      form="rectangle"
      @action="changeStatus('lost')"
    />
    <default-button
      v-if="chosenStatus === 'open'"
      size="m"
      :title="'Won'"
      :color="'success'"
      form="rectangle"
      @action="changeStatus('won')"
    />
    <default-button
      v-if="chosenStatus !== 'open'"
      size="m"
      :title="'Reopen'"
      :color="'neutral'"
      form="rectangle"
      @action="changeStatus('open')"
    />
  </div>
</template>

<script>
  import DefaultButton from '@/components/base/uiKit/DefaultButton';

  export default {
    name: 'CrmDealStatus',
    components: {
      DefaultButton,

    },
    props: {
      chosenStatus: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
      };
    },
    methods: {
      changeStatus (status) {
        this.$emit('change-status', status);
      },
    },
  };
</script>

<style lang="scss">
.buttons-deal-status {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}
</style>
