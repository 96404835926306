<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="schedule-message page__card">
    <p class="mb-2 schedule-message__title">
      Schedule Send
    </p>
    <calendar-panel
      v-model="date"
      value-type="date"
      class="schedule-message__panel mb-2"
      :disabled-date="date => date < new Date(Date.now() - 86400000)"
      @select="selectDate"
    />
    <div class="row schedule-message__inputs mb-2">
      <div class="column schedule-message__input">
        <p class="schedule-message__title">
          Date
        </p>
        <span
          class="input"
          :class="{ input_error: error.date, placeholder: !date }"
        >
          {{ (date && `${date.getMonth()+1}/ ${ ('0' + date.getDate()).slice(-2)} /${date.getFullYear()}`) || 'mm/dd/yyyy' }}
        </span>
      </div>
      <div class="column schedule-message__input">
        <p class="schedule-message__title ">
          Time
        </p>

        <div class="row">
          <input
            class="tooltip input"
            :class="{ input_error: error.time }"
            placeholder="hh:mm"
            :value="time"
            @input="setTime"
          >
          <div
            class="ampm-switch"
            :class="{'ampm-switch_active': timePart === 'am'}"
            @click="timePart === 'am'? timePart = 'pm': timePart = 'am'"
          >
            <div class="toggle-button" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="primary-btn primary-btn_icon"
        @click="send"
      >
        <clock-icon size="0.9x" />
        <span class="primary-btn__text"> Schedule</span>
      </div>
      <div
        class="primary-btn_gray primary-btn_icon"
        @click="cancel"
      >
        <x-icon size="0.9x" />
        <span class="primary-btn__text"> Cancel</span>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import 'vue2-datepicker/index.css';
  import DatePicker from 'vue2-datepicker';
  import { ClockIcon, XIcon } from 'vue-feather-icons';
  const { CalendarPanel } = DatePicker;
  const totalTest = new RegExp(/[0-9]{0,2}:?[0-9]{0,2}/);
  const fullTime = new RegExp(/[0-9]{2}:?[0-9]{2}/);
  export default {
    name: 'ScheduleMessage',
    components: {
      CalendarPanel,
      ClockIcon,
      XIcon,
    },
    data () {
      return {
        date: '',
        timeOptions: [],
        time: '',
        timePart: 'am',
        error: {
          date: false,
          time: false,
        },
      };
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
    },
    created () {
      for (let i = 0; i < 24; i++) {
        this.timeOptions.push({
          name: `${i}:00`,
          value: i,
        });
      }
    },
    mounted () {},
    methods: {
      selectDate (date) {
        this.date = date;
        this.error.date = false;
      },
      checkFields () {
        this.error.date = !this.date;
        this.error.time = !this.time || !fullTime.test(this.time);
        let offset = 0;
        if (!this.selectedCredential.ui_pc_timezone) {
          offset = (this.selectedCredential.timezone_offset + (new Date().getTimezoneOffset())) * 60 * 1000;
        }
        const credDate = new Date(+new Date() + offset);
        const isCurrentDate = credDate.toDateString() === this.date.toDateString();
        if (isCurrentDate) {
          const m = credDate.getMinutes();
          const h = credDate.getHours();
          const hours = +this.time.split(':')[0] + (this.timePart === 'am' ? 0 : 12);
          const minutes = +this.time.split(':')[1];
          if (hours < h) {
            this.error.time = true;
            this.$noty.error('You cannot send a message to the past');
          } else if (hours === h && minutes < m) {
            this.error.time = true;
            this.$noty.error('You cannot send a message to the past');
          }
        }
        return !Object.values(this.error).some(v => v);
      },
      send () {
        if (this.checkFields()) {
          const hours = +this.time.split(':')[0] + (this.timePart === 'am' ? 0 : 12);
          const minutes = +this.time.split(':')[1];
          const date = new Date(+this.date + hours * 60 * 60 * 1000 + minutes * 60 * 1000);
          this.$emit('send', date);
          this.date = '';
          this.time = '';
          this.timePart = 'am';
        }
      },
      cancel () {
        this.$emit('cancel');
        this.date = '';
        this.time = '';
        this.timePart = 'am';
      },
      setTime (e) {
        const numRegex = new RegExp(/[0-9]/);
        const parts = e.target.value.split(':');
        if (parts[0] && parts[0] > 12) {
          e.target.value = '0' + this.time;
        }
        if (parts[1] && parts[1] > 59) {
          e.target.value = parts[0] + ':' + '0' + parts[1];
        }
        if (parts.some(part => part.length > 2)) {
          e.target.value = this.time;
          return;
        }
        if (!totalTest.test(e.target.value)) {
          e.target.value = this.time;
          return;
        }
        if (e.data) {
          if (!numRegex.test(e.data)) {
            e.target.value = this.time;
            return;
          }
        }

        if (e.target.value.length === 2) {
          if (!e.target.value.includes(':')) {
            e.target.value += ':';
          }
        }
        // case (e.target.value.length === 4) && (e.target.value[3] === ':'):
        //   e.target.value = e.target.value.replace(':', '');
        //   break;
        this.error.time = false;
        this.time = e.target.value;
      },
    },
  };
</script>
<style lang="scss" scoped>
.schedule-message {
  padding: 16px 24px;
  .primary-btn_icon {
    font-size: 13px;
    flex: 1;
  }

  &__panel {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
  }
  &__input {
    flex: 1;
    .placeholder {
      color: rgba(var(--main-text-cl-rgb), 0.56) !important;
    }
  }
  &__title {
    font-size: 14px;
    line-height: 20px;
  }
  .tooltipBlock {
    width: 100%;
    left: 0;
  }
}

.ampm-switch {
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  border-radius: 3px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: var(--primary-cl);
}
.ampm-switch {
  &::after{
    content: 'PM';
    box-sizing: border-box;
    display: block;
    width: 40px;
    height: 20px;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    color: white;
  }
  &::before{
    content: 'AM';
    box-sizing: border-box;
    display: block;
    width: 40px;
    height: 20px;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    color: white;
  }
  &_active {
    .toggle-button {
      top: 20px;
      &::before {
        content: 'PM';
      }
    }
  }
}

.toggle-button {
  z-index: 2;
  display: inline-block;
  position: absolute;
  top: 0;
  padding: 0;
  margin: 0;
  width: 40px;
  height: 20px;
  background: transparent;
  white-space: nowrap;
  border: 1px solid var(--border-cl);
  color: var(--secondary-text-color);
  background-color: var(--background-color);
  transition: top 0.05s ease-in-out;
  &:before {
    content: 'AM';
    box-sizing: border-box;
    display: block;
    width: 40px;
    height: 20px;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    color: var(--secondary-text-color);
  }
}
</style>
