<template>
  <div class="flex-column flex-gap-8 pb-25 pt-25 pl-2 pr-2">
    <h1 class="text_s text_weight_accent">
      Campaign Info
    </h1>
    <div v-if="workflow?.name">
      <div class="flex-row-between">
        <p class="text_xs color_tertiary">
          Name
        </p>
        <default-button
          title="Open"
          color="transparent"
          form="rectangle"
          size="s"
          @action="goToCampaign"
        />
      </div>
      <div class="flex-row-between mb-15">
        <h2 class="text_s text_weight_accent">
          {{ workflow?.name }}
        </h2>
        <default-label
          size="xs"
          :label-name="capitalize(workflow?.status)"
          :custom-type="workflow?.status === 'active' ? 'success' : 'warning'"
        />
      </div>
      <div>
        <p class="text_xs color_tertiary mb-1 mt-1">
          Steps
        </p>
        <ul class="flex-column flex-gap-8">
          <campaign-action-status
            v-for="(action, i) in profile.next_workflow_actions"
            :key="i"
            :index="i"
            :action="action"
            :workflow-status="profile.workflow_status"
          />
        </ul>
      </div>
    </div>
    <div
      v-else
      class="flex-column-centered flex-gap-8"
    >
      <i class="bx bx-briefcase color_tertiary icon_size_24" />
      <span class="text_bold text_s">
        No Active Campaigns
      </span>
      <span class="color_tertiary text_s">
        Active campaigns will be found here
      </span>
    </div>
    <default-button
      v-if="workflow?.status === 'active'"
      class="margin-center mt-1"
      :title="isActiveWorkflowForProfile ? 'Pause Campaign' : 'Resume Campaign'"
      :icon-name="isActiveWorkflowForProfile ? 'bx-pause' : 'bx-play'"
      color="neutral"
      form="rectangle"
      size="m"
      @action="updateWorkflowStatusForProfile"
    />
    <continue-modal
      :id="profile._id"
      :show.sync="modals.continue"
      :steps="actionsForModal"
      :name="profile.first_name"
      :show-steps="workflow?.sequences_type === 'simple'"
      @resume="resume"
    />
  </div>
</template>
<script>
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import DefaultLabel from '@/components/base/uiKit/DefaultLabel.vue';
  import CampaignActionStatus from '@/components/inboxPage/info/campaign/CampaignActionStatus.vue';
  import ContinueModal from '@/modals/ContinueModal.vue';

  import { capitalizeFilterMixin } from '@/mixins/capitalizeFilterMixin';
  import { fromNowFilterMixin } from '@/mixins/fromNowFilterMixin';
  import { mapGetters } from 'vuex';

  export default {
    name: 'CampaignInfo',
    components: {
      DefaultButton,
      DefaultLabel,
      CampaignActionStatus,
      ContinueModal,
    },
    mixins: [capitalizeFilterMixin, fromNowFilterMixin],
    props: {
      profile: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        modals: {
          continue: false,
        },
      };
    },
    computed: {
      ...mapGetters({
        workflowsList: 'workflows/workflowsList',
        space: 'account/space',
        selectedCredential: 'credentials/selectedCredential',
      }),
      isActiveWorkflowForProfile () {
        return this.profile.workflow_status === 'active';
      },
      workflow () {
        return this.workflowsList.find(w => w._id === this.profile.workflow);
      },
      actionsForModal () {
        return this.workflow?.sequence.map(a => {
          a.done = this.profile.done_sequence_hash.includes(a.id);
          return a;
        });
      },
    },
    methods: {
      goToCampaign () {
        this.$router.push({
          name: 'edit-campaign',
          params: {
            id: this.workflow._id,
          },
          query: {
            space: this.space,
            selectedCred: this.selectedCredential._id,
          },
        });
      },
      updateWorkflowStatusForProfile () {
        if (this.isActiveWorkflowForProfile) {
          this.$emit('update-profile-prop', { workflow_status: 'inactive' });
        } else {
          this.workflow.sequences_type === 'advanced' ? this.resume() : this.modals.continue = true;
        }
      },
      resume (index) {
        this.modals.continue = false;
        this.$emit('update-profile-prop', {
          workflow_status: 'active',
          current_workflow_step: index,
        });
      },

    },
  };
</script>
