import { render, staticRenderFns } from "./ScheduleMessage.vue?vue&type=template&id=3b7f2a98&scoped=true&"
import script from "./ScheduleMessage.vue?vue&type=script&lang=js&"
export * from "./ScheduleMessage.vue?vue&type=script&lang=js&"
import style0 from "./ScheduleMessage.vue?vue&type=style&index=0&id=3b7f2a98&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../temp_divo-ui/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b7f2a98",
  null
  
)

export default component.exports