<template>
  <li class="flex-row-between">
    <div class="flex-row flex-gap-8">
      <p class="text_xs color_tertiary">
        {{ props.action.stepNumber }}
      </p>
      <i :class="['bx icon_size_16 color_tertiary', icon]" />
      <p class="text_xs text_weight_accent">
        {{ props.action.name }}
      </p>
    </div>
    <div class="flex-row flex-gap-8">
      <div
        v-if="status && status != 'skipped'"
        class="flex-row flex-gap-8"
      >
        <i
          :class="[
            'bx icon_size_16',
            statusIcon
          ]"
        />
        <default-label
          v-if="props.action.type === 'condition'"
          size="xs"
          :label-name="conditionStyle.name"
          :custom-type="conditionStyle.type"
        />
      </div>
      <p
        v-else
        class="text_xs color_tertiary"
      >
        {{ description }}
      </p>
    </div>
  </li>
</template>
<script setup>
  import DefaultLabel from '@/components/base/uiKit/DefaultLabel.vue';
  import { fromNowFull } from '@/mixins/fromNowFilterMixin';
  import { computed } from 'vue';
  const props = defineProps({
    action: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    workflowStatus: {
      type: String,
      required: true,
    },
  });
  const status = computed(() => {
    if (props.action.status === 'unknown') {
      return 'done';
    }
    if (props.action.name === 'finished') {
      return 'done';
    }
    return props.action.status;
  });
  const statusIcon = computed(() => {
    switch (status.value) {
    case 'done':
      return 'bx-check color_success';
    case 'unknown':
      return 'bx-check color_success';
    case 'failed':
      return 'bx-x color_danger';
    case 'wip':
      return 'bx-loader-circle color_tertiary';
    case 'skipped':
      return 'bx-skip-next color_tertiary';
    }
    return '';
  });

  const conditionStyle = computed(() => {
    switch (status.value) {
    case 'done':
      return { name: 'Positive', type: 'success' };
    case 'failed':
      return { name: 'Negative', type: 'danger' };
    case 'skipped':
      return { name: 'Skipped', type: 'neutral' };
    default:
      return { name: '', type: null };
    }
  });
  const description = computed(() => {
    if (props.action.status === 'skipped') {
      return 'skipped';
    }
    if (props.workflowStatus !== 'active') {
      return 'paused';
    }

    return Date.parse(props.action.delay_date) > Date.now()
      ? `in ${fromNowFull(props.action.delay_date)}`
      : 'in queue';
  });

  const icon = computed(() => {
    switch (props.action.action_type) {
    case 'send_email':
      return 'bx-mail-send';
    case 'li_actions':
      return 'bxs-user-detail';
    case 'li_connect':
      return 'bx-send';
    case 'enrich_profile':
      return 'bx-diamond';
    case 'li_send_message':
      return 'bx-message-detail';
    case 'li_send_inmail':
      return 'bx-message-detail';
    case 'li_connect_by_email':
      return 'bx-send';
    case 'send_webhook':
      return 'bx-sitemap';
    }
    if (props.action.name === 'finished') {
      return 'bx-check-square';
    }
    if (props.action.type === 'condition') {
      return 'bx-spreadsheet';
    }
    return '';
  });
</script>
