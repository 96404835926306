<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    class="crm-table-column"
    @mousedown.stop="grabPage"
    @mouseup.stop="unGrabPage"
    @mousemove.stop="dragPage"
  >
    <validation-observer
      v-if="isAddStage"
      v-slot="{ passes }"
    >
      <form
        @submit.prevent="passes(saveStage)"
      >
        <div
          class="crm-table-column__add-stage-active"
        >
          <div class="crm-table-column__item-header" />
          <default-input
            size="s"
            :value.sync="stageName"
            validation-string="required"
            placeholder="Stage Name"
          />

          <div class="crm-table__flex">
            <default-input
              size="s"
              :value.sync="probability"
              validation-string="required|integer|between:0,100"
              placeholder="Probability"
            />
            <default-button
              size="m"
              color="danger"
              form="circle"
              icon-name="bx-x"
              @action="isAddStage = false"
            />
            <default-button
              size="m"
              color="success"
              type="submit"
              form="circle"
              icon-name="bx-check"
            />
          </div>
        </div>
      </form>
    </validation-observer>
    <div
      v-else
      class="crm-table-column__item"
    >
      <div class="crm-table-column__item-header" />
      <div class="crm-table-column__info">
        <span class="crm-table-column__text text_s crm-table-column__text_hidden">{{ stage.name }}</span>
        <div
          class="crm-table-column__info-flex"
        >
          <span
            class="crm-table-column__text text_xs color_secondary"
          >{{ stage?.crm_deals?.length || 0 }}
            {{ 'deal' + (stage.crm_deals?.length > 1? 's': '') }}</span>
          <span class="crm-table-column__dot" />
          <span
            class="crm-table-column__text text_xs color_secondary"
          >{{ stage.probability+'% probability' }}</span>
        </div>
      <!--        <span class="crm-table-column__text text_xs color_secondary">lol</span>-->
      </div>
      <default-tooltip
        v-if="$route.params.integration === 'default' "
        class="crm-table-column__tooltip"
        data-closable="stageMenu"
        :has-triangle="false"
        :show.sync="isShowTooltip"
        :size="'s'"
        :items="menuFields"
        @choose="(item) => { selectMenuItem(item, stage) }"
      >
        <template #default>
          <i
            class="bx bx-dots-horizontal-rounded color_secondary crm-table-column__item-icon"
          />
        </template>
      </default-tooltip>
    </div>
    <draggable
      v-model="stageDeals"
      :animation="200"
      ghost-class="ghost-card"
      group="guests"
      class="crm-table-column__deal"
      :disabled="!loaded"
      draggable=".item"
      @change="changeOrder($event, index)"
    >
      <template v-for="deal in stage.crm_deals">
        <crm-deal-card
          v-if="!deal.deleted_at"
          :key="deal.name"
          class="crm-table-column__deal-card"
          :deal="deal"
          @open-modal-deal="(myDeal) => { openCrmDealModal(stage, myDeal) }"
        />
      </template>
      <div
        class="crm-table-column__add-deal"
        @click.stop="openCrmDealModal(stage)"
      >
        <span class="crm-table-column__text text_xs">
          <i class="bx bx-plus" />
          Add Deal
        </span>
      </div>
    </draggable>
  </div>
</template>

<script>

  import DefaultTooltip from '@/components/base/uiKit/DefaultTooltip';
  import CrmDealCard from '@/components/CRMIntegration/CrmDealCard.vue';
  import DefaultInput from '@/components/base/uiKit/DefaultInput';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import { ValidationObserver } from 'vee-validate';

  import draggable from 'vuedraggable';

  export default {
    name: 'CrmTableColumn',
    components: {
      DefaultTooltip,
      CrmDealCard,
      draggable,
      DefaultButton,
      DefaultInput,
      ValidationObserver,
    },
    mixins: [],
    props: {
      stage: {
        type: Object,
        default: () => {},
      },
      selectedPipeline: {
        type: Object,
        default: () => {},
      },
      index: {
        type: Number,
        default: 1,
      },
      loaded: {
        type: Boolean,
        default: true,
      },
      deals: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        isAddStage: false,
        stageName: '',
        probability: '',
        stageDeals: this.deals,
        isShowTooltip: false,
        menuFields: [
          {
            name: 'Edit',
            iconName: 'bx-edit-alt',
            _id: 'edit',
          },
          {
            name: 'Delete',
            iconName: 'bx-trash',
            _id: 'delete',
          },
        ],
      };
    },
    computed: {
    },
    watch: {
      stageDeals () {
        this.$emit('update:deals', this.stageDeals);
      },
    },
    mounted () {},
    methods: {
      grabPage (e) {
        this.$emit('grab-page', e);
      },
      dragPage (e) {
        this.$emit('drag-page', e);
      },
      unGrabPage () {
        this.$emit('un-grab-page');
      },
      changeOrder (deal, i) {
        this.$emit('un-grab-page');
        this.$emit('change-order', deal, i);
      },
      openCrmDealModal (stage, deal) {
        this.$emit('open-crm-deal-modal', stage, deal);
      },
      saveStage () {
        if (Number(this.probability) >= 0 && Number.isInteger(Number(this.probability))) {
          this.$emit('save-stage', this.stageName, this.probability, this.selectedPipeline.external_id, this.stage._id);
          this.stageName = '';
          this.probability = '';
          this.isAddStage = false;
        } else this.$noty.error('Probability must be a positive number');
      },
      selectMenuItem (item, stage) {
        if (stage && item._id === 'edit') {
          this.stageName = stage.name;
          this.probability = stage.probability;
          this.isAddStage = !this.isAddStage;
        }
        if (item._id === 'delete') {
          this.$emit('delete-stage', stage);
        };
      },
    },
  };
</script>

<style lang="scss">
.crm-table-column {
    &__deal-card {
      width: 188px;
    }

    display: flex;
    flex-flow: column;
    gap: 8px;
    height: calc(100vh - 76px - 151px);
  &__item {
    padding: 8px;
    display: flex;
    width: 188px;
    position: relative;
    background: var(--table-bg);
    border-radius: 4px;
    margin-bottom: 8px;
  &-header {
      background: var(--primary-cl);
      height: 4px;
      border-radius: 4px 4px 0px 0px;
      top: 0;
      width: 188px;
      left: 0;
      position: absolute;
    }
    &-icon {
      cursor: pointer;
      font-size: 24px;
      display: flex;
      margin-left: auto;
      align-items: center;
      &:hover {
        color: var(--primary-cl);
      }
    }
  }
  &__info {
    display: block;
    &-flex {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  &__deal {
    display: flex;
    flex-flow: column;
    gap: 8px;
    min-height: 100%;
    transition: 0.3s;
    width: 100%;
    overflow: scroll;
    &:hover {
      .crm-table-column__add-deal {
        display: flex;
      }
    }
    // overflow-y: auto;
    // height: calc(100vh - 76px - 62px - 40px);
  }
  &__add-deal, &__add-stage {
    min-height: 100px;
    width: 188px;
    border: 1px dashed var(--secondary-text-color);
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    cursor: pointer;
    color: var(--primary-cl);
    transition: 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }
  &__add-deal {
    display: none;
  }
  &__add-stage {
    height: 48px;
    min-height: 48px;
    flex-shrink: 0;
  }
  &__add-stage-active {
     padding: 8px;
    display: flex;
    min-width: 188px;
    max-width: 188px;
    position: relative;
    background: var(--table-bg);
    border-radius: 4px;
    margin-bottom: 8px;
    height: min-content;
    flex-flow: column;
    gap: 4px;
  &-header {
      background: var(--primary-cl);
      height: 4px;
      border-radius: 4px 4px 0px 0px;
      top: 0;
      width: 188px;
      left: 0;
      position: absolute;
    }
  }
  &__flex {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  &__dot {
    background: var(--secondary-text-color);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
  }
  &__tooltip {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  &__text {
    display: flex;
    align-items: center;
    gap: 4px;
    &_hidden {
      display: block;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
}
}
</style>
