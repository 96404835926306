/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
const moment = require('moment');

export const getParseDate = (date) => {
  let newDate;
  const times = moment().valueOf() - moment(date).valueOf() + (new Date().getTimezoneOffset() * 60000);

  if (times < 31536000000) {
    newDate = `${Math.floor(times / 2419200000)} months ago`;
    if (newDate === '1 month ago') {
      newDate = '1 month ago';
    }
    if (newDate === '0 months ago') {
      newDate = `${Math.floor(times / 604800000)} weeks ago`;
      if (newDate === '1 weeks ago') {
        newDate = '1 week ago';
      }
      if (newDate === '0 weeks ago') {
        newDate = `${Math.floor(times / 86400000)} days ago`;
        if (newDate === '1 days ago') {
          newDate = '1 day ago';
        }
        if (newDate === '0 days ago') {
          newDate = `${Math.floor(times / 3600000)} hours ago`;
          if (newDate === '1 hours ago') {
            newDate = '1 hour ago';
          }
          if (newDate === '0 hours ago') {
            newDate = `${Math.floor(times / 60000)} min ago`;
            if (newDate === '0 min ago') {
              newDate = 'Just now';
            }
          }
        }
      }
    }
  } else {
    newDate = moment(date).format('DD/MM/YYYY HH:mm');
  }

  return newDate;
};
