<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="prospects">
    <div class="prospects__header">
      <span class="prospects__text">Prоspects</span>
      <span
        v-if="prospects.length && selectedCrm !== 'pipedrive'"
        class="prospects__text prospects__text_link text_xs"
        @click="addProspect"
      >
        <i class="bx bx-plus" />
        Add
      </span>
    </div>
    <div
      v-if="!prospects.length"
      class="prospects__add"
      @click="addProspect"
    >
      <span class="prospects__text text_xs">
        <i class="bx bx-plus" />
        Add
      </span>
    </div>
    <div
      v-else
      class="prospects__block"
    >
      <crm-chosen-item
        v-for="prospect in prospects"
        :key="prospect._id"
        :prospect="prospect"
        @delete-item="deleteItem"
      />
    </div>
  </div>
</template>
<script>

  import CrmChosenItem from '@/modals/CrmModals/CrmChosenItem';

  export default {
    name: 'ChosenProspects',
    components: {
      CrmChosenItem,
    },
    props: {
      prospects: {
        type: Array,
        default: () => [],
      },
      selectedCrm: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
      };
    },
    methods: {
      handleClose () {
        this.$emit('update:show', false);
      },
      addProspect () {
        this.$emit('add-item', false);
      },
      deleteItem (item, isCompany) {
        this.$emit('delete-item', item, isCompany);
      },
    },
  };
</script>

<style lang="scss">
  .prospects {
    display: flex;
    flex-flow: column;
    flex: 1;
    gap: 8px;
  &__block {
    max-height: 360px;
    padding: 4px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-flow: column;
    flex: 1;
    gap: 8px;
  }
  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &__position {
    display: flex;
    flex-flow: column;
  }
  &__image {
    border-radius: 100%;
    width: 16px;
    height: 16px;
  }
    &__add {
      min-height: 92px;
      width: 244px;
      border: 1px dashed var(--secondary-text-color);
      display: flex;
      align-items: center;
      border-radius: 8px;
      justify-content: center;
      cursor: pointer;
      color: var(--primary-cl);
      &:hover {
        opacity: 0.7;
      }
      &_disabled {
        opacity: 0.7;
        pointer-events: none;
      }
    }
  &__text {
    display: flex;
    align-items: center;
    gap: 4px;
    &_link {
      color: var(--primary-cl);
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    &_margin-left {
      margin-left: auto;
    }
  }
}
</style>
