var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"workspace-appearance"},[_c('div',{staticClass:"workspace-appearance__buttons"},[_c('button',{staticClass:"workspace-appearance__button",on:{"click":function($event){_vm.isHorizontal = false}}},[_c('img',{class:[
          'workspace-appearance__img',
          {'workspace-appearance__img_active':!_vm.isHorizontal}
        ],attrs:{"src":require("@/assets/VerticalSidebar.svg")}}),_c('span',{staticClass:"text_xs workspace-appearance__button-text"},[_vm._v("Vertical")])]),_c('button',{staticClass:"workspace-appearance__button",on:{"click":function($event){_vm.isHorizontal = true}}},[_c('img',{class:[
          'workspace-appearance__img',
          {'workspace-appearance__img_active': _vm.isHorizontal}
        ],attrs:{"src":require("@/assets/HorizontalSidebar.svg")}}),_c('span',{staticClass:"text_xs workspace-appearance__button-text"},[_vm._v("Horizontal")])])]),_c('div',{staticClass:"flex flex-justify-content-end mt-16"},[_c('default-button',{attrs:{"title":"Save changes","color":"primary","form":"rectangle","size":"m"},on:{"action":_vm.saveChange}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }