<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="send-inmail-step">
    <div
      class="send-inmail-step__textarea-container"
      @mouseover="showTemplateMenu"
      @mouseleave="isShowTemplateMenu = false"
    >
      <div
        class="send-inmail-step__header"
      >
        <span class="text_s color_secondary">Subject:</span>
        <input
          ref="inmailSubject"
          v-model="inmailSubject"
          placeholder=""
          :readonly="readOnly"
          class="send-inmail-step__input ui-kit-input__item text_s"
          @focus="isSubject = true"
          @mouseover="showTemplateMenu"
          @mouseleave="isShowTemplateMenu = false"
        >
      </div>
      <div class="send-inmail-step__error">
        <span
          v-for="(error, key) in messageError"
          :key="`${key}Message`"
          class="text_xs"
          :class="key === 'warningText' ? 'color_warning' : 'color_danger'"
        >
          <i
            v-if="error"
            class="bx bx-info-circle"
          />
          {{ error }}
        </span>
        <span
          v-for="(error, key) in subjectError"
          :key="`${key}Subject`"
          class="text_xs"
          :class="key === 'warningText' ? 'color_warning' : 'color_danger'"
        >
          <i
            v-if="error"
            class="bx bx-info-circle"
          />
          {{ error }}
        </span>
      </div>
      <textarea
        id="countWords"
        ref="messageArea"
        v-model="innerMessage"
        placeholder="Type your message"
        :readonly="readOnly"
        class="send-inmail-step__textarea"
        @focus="isSubject = false"
      />
      <template-unlink-menu
        v-show="isShowTemplateMenu"
        class="connection-request__template-menu"
        :class="{'connection-request__template-menu_active': isShowTemplateMenu}"
        :template="template"
        @unlink-template="unlinkTemplate"
        @edit-template="editTemplate"
      />
    </div>
    <div class="send-inmail-step__credits">
      <default-switcher
        :is-checked="sales"
        size="s"
        @change="switchSales"
      />
      <div class="send-inmail-step__credits-desc">
        <span class="text_xs text_bold">Allow {{ user.config.name }} to use my InMail credits</span>
        <span class="text_xs color_tertiary">InMails will be sent to any account including private and premium ones, using your available account's InMail credits.</span>
      </div>
    </div>
    <step-menu
      :brand="brand"
      :video="video"
      :template="template"
      :message="innerMessage"
      :subject="inmailSubject"
      type="li_send_inmail"
      @add-template-to-area="addTemplate"
      @add-practice="addPractice"
      @remove-video="removeVideo"
      @insert-video="insertVideo"
      @unlink-template="unlinkTemplate"
      @choose-video="$emit('choose-video')"
      @choose-template="$emit('choose-template')"
    />
  </div>
</template>
<script>
  import { addTemplateMixin } from '@/mixins/addTemplateMixin.js';
  import StepMenu from '@/components/updateWorkflow/performAction/menuFiles/StepMenu';
  import DefaultSwitcher from '@/components/base/uiKit/DefaultSwitcher';
  import { mapGetters } from 'vuex';
  import TemplateUnlinkMenu from '@/components/updateWorkflow/performAction/menuFiles/TemplateUnlinkMenu';

  export default {
    name: 'SendInmailStep',
    components: {
      DefaultSwitcher,
      StepMenu,
      TemplateUnlinkMenu,
    },
    mixins: [addTemplateMixin],
    props: {
      brand: {
        type: String,
        default: '',
      },
      messageError: {
        type: Object,
        default: () => ({
          warningText: '',
          errorText: '',
        }),
      },
      subjectError: {
        type: Object,
        default: () => ({
          warningText: '',
          errorText: '',
        }),
      },
      message: {
        type: String,
        default: '',
      },

      subject: {
        type: String,
        default: '',
      },
      isSales: {
        type: Boolean,
        default: false,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      template: {
        type: Object,
        default: null,
      },
      video: {
        type: Object,
        default: null,
      },
    },
    data () {
      return {
        innerMessage: this.message,
        inmailSubject: this.subject,
        isSubject: false,
        isShowTemplateMenu: false,
        sales: this.isSales,
      };
    },
    computed: {
      ...mapGetters({
        account: 'account/account',
        user: 'user/user',
      }),
    },
    watch: {
      innerMessage () {
        this.$emit('update:message', this.innerMessage);
      },
      inmailSubject () {
        this.$emit('update:subject', this.inmailSubject);
      },
      template () {
        if (this.template) {
          this.innerMessage = this.template?.text_template || '';
          this.inmailSubject = this.template?.subject || '';
        }
      },
      message () {
        this.innerMessage = this.message;
      },
    },
    methods: {
      addThread (thread) {
        this.chosenThread = thread;
        this.emailSubject = thread.name;
      },
      checkSubject () {
        if (!this.inmailThreading.find(e => this.inmailSubject === e?.name)) {
          this.chosenThread = null;
        }
      },
      switchSales () {
        this.sales = !this.sales;
        this.$emit('update:isSales', this.sales);
      },
      unlinkTemplate () {
        this.isShowTemplateMenu = false;
        this.$emit('unlink-template');
      },
      editTemplate (template) {
        this.isShowTemplateMenu = false;
        this.$emit('edit-template', template);
      },
      showTemplateMenu () {
        if (this.readOnly) {
          this.isShowTemplateMenu = true;
        }
      },
      addTemplate (template) {
        if (this.isSubject) {
          this.inmailSubject = this.addTemplateToArea(this.inmailSubject, template, 'inmailSubject');
        } else this.innerMessage = this.addTemplateToArea(this.innerMessage, template);
      },
      insertVideo (video) {
        this.$emit('insert-video', video);
      },
      removeVideo (video) {
        this.$emit('remove-video', video);
      },
      addPractice (item) {
        this.inmailSubject = item.subject;
        this.innerMessage = item.message;
      },
    },
  };
</script>
<style lang="scss">
.send-inmail-step {
  &__header {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 4px;
  }
  &__error {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  &__textarea {
    resize: none;
    width: 100%;
    border: none;
    padding: 8px 16px;
    min-height: 160px;
    border-top: 1px solid rgba(10, 32, 51, 0.06);
    border-bottom: 1px solid rgba(10, 32, 51, 0.06);
    background: var(--neutral-50);
  }
  &__input {
    min-height: 24px;
  }
  &__template-menu {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    transition: 0.3s;
    &_active {
      opacity: 1;
    }
  }
  &__textarea-container {
    position: relative;
  }
  &__credits {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;
    &-desc {
      display: flex;
      flex-flow: column;
    }
  }
}
</style>
