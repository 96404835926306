<template>
  <div class="flex-column flex-gap-16">
    <h1 class="text_s text_weight_accent pl-2 pr-2">
      Contacts
    </h1>
    <div>
      <p class="text_xs color_tertiary pl-2 pr-2">
        Direct
      </p>
      <custom-item-contacts
        v-for="contact in directContacts"
        :key="contact.meta.key"
        class="pt-05 pr-2 pb-05 pl-2"
        :contact="contact"
        @change="change(contact.meta.profileKey, $event)"
      />
    </div>
    <div>
      <p class="text_xs color_tertiary pl-2 pr-2">
        Work
      </p>
      <custom-item-contacts
        v-for="contact in workContacts"
        :key="contact.meta.profileKey"
        class="pt-05 pr-2 pb-05 pl-2"
        :contact="contact"
        @change="change(contact.meta.profileKey, $event)"
      />
    </div>
    <enriched-block-contacts
      :profile="profile"
      class="pr-2 pl-2"
      @update-profile="$emit('update-profile', $event)"
    />
  </div>
</template>
<script>
  import CustomItemContacts from '@/components/inboxPage/info/basic/contacts/CustomItemContacts';
  import EnrichedBlockContacts from '@/components/inboxPage/info/basic/contacts/EnrichedBlockContacts';

  export default {
    name: 'ContactsBasic',
    components: {
      CustomItemContacts,
      EnrichedBlockContacts,
    },
    props: {
      profile: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        contacts: [
          {
            name: 'Email',
            icon: 'bx bx-envelope',
            value: '',
            meta: {
              category: 'direct',
              profileKey: 'email',
            },
          },
          {
            name: 'Phone',
            icon: 'bx bx-phone',
            value: '',
            meta: {
              category: 'direct',
              profileKey: 'phone',
            },
          },
          {
            name: 'Twitter',
            icon: 'bx bxl-twitter',
            value: '',
            meta: {
              category: 'direct',
              profileKey: 'twitter',
            },
          },

          {
            name: 'Email',
            icon: 'bx bx-envelope',
            value: '',
            meta: {
              category: 'work',
              profileKey: 'work_email',
            },
          },
          {
            name: 'Phone',
            icon: 'bx bx-phone',
            value: '',
            meta: {
              category: 'work',
              profileKey: 'company_phone',
            },
          },
        ],
      };
    },
    computed: {
      directContacts () {
        return this.contacts.filter(c => c.meta.category === 'direct');
      },
      workContacts () {
        return this.contacts.filter(c => c.meta.category === 'work');
      },
    },
    watch: {
      'profile._id' () {
        this.setContacts();
      },
    },
    created () {
      this.setContacts();
    },
    methods: {
      setContacts () {
        this.contacts = this.contacts.map(c => {
          c.value = this.profile[c.meta.profileKey];
          return c;
        });
      },
      change (key, newValue) {
        this.contacts = this.contacts.map(c => {
          if (c.meta.profileKey === key) {
            c.value = newValue;
          }
          return c;
        });
        const updatedProp = { [key]: newValue };
        this.$emit('update-profile-prop', updatedProp);
      },
    },
  };
</script>
