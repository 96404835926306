/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
export const normalizeNameByStatusFilterMixin = {
  filters: {
    normalizeNameByStatus: function (status) {
      switch (status) {
        case 'new': {
          return 'New';
        }
        case 'request_sent': {
          return 'Contacted ';
        }
        case 'connected': {
          return '\nConnected';
        }
        case 'unconnectable': {
          return 'Unreachable';
        }
        case 'fetched_email': {
          return 'Got \nemail';
        }
        case 'viewed_message': {
          return 'Viewed \nmessage';
        }
        case 'visited_link': {
          return 'Visited \nlink';
        }
        case 'got_reply': {
          return 'Replied';
        }
        case 'contacted': {
          return 'Contacted';
        }
        case 'interested': {
          return 'Lead';
        }
        case 'stuck': {
          return 'Pending';
        }
        case 'lead': {
          return 'Customer';
        }
        case 'acceptance_rate': {
          return 'Accepted';
        }
        case 'response_rate': {
          return 'Reply rate';
        }
        case 'open_rate': {
          return 'Email open rate';
        }
        case 'click_rate': {
          return 'Email click rate';
        }
        case 'message_click_rate': {
          return 'Message click rate';
        }
        case 'bounce_rate': {
          return 'Bounce rate';
        }
        default: {
          return 'Excluded';
        }
      }
    },
  },
};
