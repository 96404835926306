<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    v-if="info"
    class="info-item"
  >
    <span
      v-if="info.subject"
      class="info-item__subject text_s color_secondary"
    >
      {{ info.subject }}
    </span>
    <span
      v-if="info.message"
      :class="[
        'info-item__message text_s color_secondary',
        { 'info-item__message-3': !info.subject },
      ]"
    >
      {{ info.message }}
    </span>
    <template v-if="info.email_type">
      <p class="info-item__message text_s color_secondary">
        {{ info.email_type === 'work' ? 'Work' : info.email_type == 'predicted' ? 'Enriched' : 'Personal' }} email content
      </p>
      <p class="info-item__message text_s color_secondary">
        Click to view email
      </p>
    </template>
    <template v-if="info.actions">
      <span
        v-for="action in info.actions"
        :key="action"
        class="info-item__message text_s color_secondary"
      >
        {{ action | normalizeNameByAction }}
      </span>
    </template>
  </div>
</template>
<script>
  import { normalizeNameByActionMixin } from '@/mixins/normalizeNameByActionMixin';

  export default {
    name: 'InfoItem',
    mixins: [normalizeNameByActionMixin],
    props: {
      info: {
        type: Object,
        default: null,
        require: true,
      },
    },
  };
</script>
<style lang="scss" scoped>
  .info-item {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    &__message {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* number of lines to show */
      &-3 {
        -webkit-line-clamp: 3; /* number of lines to sho */
      }
    }
  }
</style>
