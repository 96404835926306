
const state = () => ({
  user: null,
  isGHLConnected: false,
});

const getters = {
  user: state => {
    return state.user;
  },
  isGHLConnected: state => {
    return state.isGHLConnected;
  },
};

const mutations = {
  SET_USER (state, payload) {
    state.user = payload;
    state.isGHLConnected = payload.config.is_ghl_connected;
  },
};

const actions = {
  SET_USER ({ commit }, payload) {
    commit('SET_USER', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
