<template>
  <li class="flex-column flex-align-items-start">
    <div
      :class="[
        'inbox-message__body',
        'inbox-message__body_' + (message.is_me ? 'right' : 'left')
      ]"
      :style="bgColor"
    >
      <div ref="messageText">
        <!-- Place to insert -->
      </div>
      <default-button
        v-if="['pending', 'failed'].includes(message.status)"
        class="inbox-message__button"
        icon-name="bx-x"
        color="danger"
        form="circle"
        :is-disabled="isDeleting"
        size="s"
        @action="deleteMessageHandler"
      />
    </div>
    <div class="flex flex-gap-4 color_tertiary">
      <i
        v-if="message.mode === 'sales_navigator'"
        class="bx bx-compass icon_size_16"
      />
      <i
        v-if="message.is_me"
        :class="['bx icon_size_16', icon]"
      />
      <default-button
        v-if="['wait', 'declined'].includes(message.status)"
        class="mt-05"
        form="ellipse"
        title="Approve"
        icon-name="bx-check"
        title-color="color_success"
        border-color="success"
        size="s"
        color="none"
        @action="$emit('edit-approval', true)"
      />
      <default-button
        v-if="['approved'].includes(message.status)"
        class="mt-05"
        form="ellipse"
        :pointer-events="false"
        title="Approved"
        icon-name="bx-check"
        size="s"
        color="success"
      />
      <default-button
        v-if="['declined'].includes(message.status)"
        class="mt-05"
        form="ellipse"
        :pointer-events="false"
        title="Paused"
        icon-name="bx-pause"
        color="primary"
        size="s"
      />
      <default-button
        v-if="['wait', 'approved'].includes(message.status)"
        class="mt-05"
        form="ellipse"
        title="Pause"
        title-color="color_primary"
        icon-name="bx-pause"
        size="s"
        border-color="primary"
        color="none"
        @action="$emit('edit-approval', false)"
      />
      <p class="text_xs">
        {{ date }}
      </p>
    </div>
  </li>
</template>
<script>
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import { httpRegex, urlRegex, emailRegex } from '@/data/regexes';
  import moment from 'moment';
  import { mapGetters } from 'vuex';
  import { deleteMessage } from '@/api/messagesMethods';

  export default {
    name: 'CardMessages',
    components: {
      DefaultButton,
    },
    props: {
      message: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        isDeleting: false,
        approvedStatuses: [
          'wait',
          'declined',
          'approved',
        ],
      };
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
      status () {
        switch (this.message.status) {
        case 'pending':
          return 'ClockIcon';
        case 'wait':
          return '';
        case 'declined':
          return '';
        case 'approved':
          return '';
        default:
          return this.getDate(this.message.created_at);
        }
      },
      icon () {
        switch (this.message.status) {
        case 'pending':
          return 'bx-time color_warning';
        case 'seen':
          return 'bx-check-double color_success';
        case 'sent':
          return 'bx-check color_primary';
        case 'failed':
          return 'bx-x color_danger';
        default:
          return '';
        }
      },
      bgColor () {
        if (!this.message.is_me) {
          if (this.approvedStatuses.includes(this.message.status)) {
            return { background: `${this.user.config.color}1A` };
          } else if (this.$route.name === 'my-prospects') {
            return { background: '#F9F9F9' };
          }
        }
        return '';
      },

      date () {
        const myTimeZone = new Date().getTimezoneOffset();
        let momentDate = this.selectedCredential.ui_pc_timezone
          ? moment(new Date(new Date(this.message.created_at) - myTimeZone * 1000 * 60))
          : moment(this.message.created_at).utcOffset(this.selectedCredential.timezone_offset - myTimeZone);
        momentDate = momentDate.format('MMM DD, h:mm a');
        return momentDate || 'Pending';
      },
    },
    mounted () {
      this.transformMessage();
    },
    methods: {
      transformMessage () {
        const p = document.createElement('p');
        p.setAttribute('class', 'inbox-message__text');
        let text = this.message.text;

        let links = text.match(urlRegex);
        const emails = text.match(emailRegex);
        links = emails
          ? (links ? [...links, ...emails] : emails)
          : links;
        if (links) {
          links = links.filter(link => link.length > 5);
          links.forEach((link) => {
            // create link
            const a = document.createElement('a');
            let href = link;
            if (href.match(emailRegex)) {
              href = 'mailto:' + href;
            } else {
              if (!href.match(httpRegex)) {
                href = 'https://' + href.replace('www.', '');
              }
            }
            a.setAttribute('href', href);
            a.setAttribute('target', '_blank');
            a.setAttribute('class', 'text_underlined');
            a.innerHTML = link;

            text = text.replace(link, a.outerHTML);
          });
        }
        p.innerHTML = text;
        this.$refs.messageText.innerHTML = p.outerHTML;
      },
      async deleteMessageHandler () {
        try {
          this.isDeleting = true;
          await deleteMessage(this.message._id, this.selectedCredential._id);
          this.$emit('delete-message', this.message);
        } catch (e) {
          this.$noty.error(e.message);
          this.isDeleting = false;
        }
      },
    },
  };
</script>
<style lang="scss">
.inbox-message {
  &__text {
    @include text_s;
    word-break: break-word;
    white-space: pre-line;
  }

  &__body {
    position: relative;
    padding: 8px 16px;

    &_right {
      background: var(--primary-cl);
      border-radius: 12px 12px 0 12px;

      p {
        color: $cl-white;
      }
    }

    &_left {
      background: var(--background-color);
      border-radius: 12px 12px 12px 0;

      p {
        color: var(--text-color);
      }
    }
  }

  &__button {
    position: absolute;
    top: 0;
    right: calc(100% + 4px);
  }
}
</style>
