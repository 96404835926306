/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
export const defaultFilters = [
  {
    category: {
      name: 'Filters',
      _id: 'filters',
      icon: 'bx-filter-alt',
    },
    items: [
      {
        name: 'Unread messages',
        _id: 'unread',
      },
      {
        name: 'Started within 7 days',
        _id: 'started_7days',
      },
      {
        name: 'No reply within 7 days',
        _id: 'no_reply_7days',
      },
      {
        name: 'Only paused campaigns',
        _id: 'paused_campaigns',
      },
      {
        name: 'Only with notes',
        _id: 'with_notes',
      },
    ],
  },
  {
    category: {
      name: 'Status in campaign',
      _id: 'statuses',
      icon: 'bx-station',
    },
    items: [
      {
        name: 'Got Reply',
        _id: 'got_reply',
      },
      {
        name: 'Lead',
        _id: 'interested',
      },
      {
        name: 'Customer',
        _id: 'lead',
      },
    ],
  },
]
;
