var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"labelsTooltip",staticClass:"tooltip"},[_c('delete-confirmation-modal',{attrs:{"text":"Confirm deleting label from space","button-text":"Delete label","show":_vm.showDeleteModal,"name":"deleteLabel"},on:{"update:show":function($event){_vm.showDeleteModal=$event},"confirm":_vm.deleteLabel}}),_c('update-label-modal',{attrs:{"show":_vm.showCreateLabelModal},on:{"update:show":function($event){_vm.showCreateLabelModal=$event},"update-label":_vm.createLabel}}),(_vm.showButton)?_c('div',{ref:"addLabels",staticClass:"profile-action-button primary-cl",on:{"click":function($event){_vm.showLablesTooltipLocal = true}}},[_c('plus-icon',{attrs:{"size":"1.3x"}}),_c('span',{staticClass:"text primary-cl"},[_vm._v("Add Label")])],1):_vm._e(),_c('checkbox-tooltip',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLablesTooltip || (_vm.showButton && _vm.showLablesTooltipLocal)),expression:"showLablesTooltip || (showButton && showLablesTooltipLocal)"}],ref:"tooltip",staticClass:"people-profile__tooltip",attrs:{"all-items":[
      {
        category: { name: 'MY LABELS', _id: 'labels' },
        /*items: allLabels.map((label) => label.checkmarkStyle = {borderColor: label.color}),*/
        items: _vm.allLabels,
      },
    ],"chosen-items":[
      { category: { name: 'MY LABELS', _id: 'labels' }, items: _vm.chosenLabels },
    ],"styles":_vm.customStyle,"pass-through":true,"show-actions":true},on:{"delete-profile-label":_vm.deleteProfileLabel,"add-profile-label":_vm.addProfileLabel},scopedSlots:_vm._u([{key:"itemAction",fn:function(slotProps){return [_c('div',{staticClass:"delete-label-button flex-row",on:{"click":function($event){return _vm.openDeleteModal(slotProps.item)}}},[_c('i',{staticClass:"bx bx-x text_m delete-button__icon"})])]}},{key:"tooltipActions",fn:function(){return [_c('div',{staticClass:"tooltip-block__action-button",on:{"click":function($event){_vm.showCreateLabelModal = true}}},[_c('i',{staticClass:"bx bx-plus tooltip-block__action-icon"}),_c('p',{staticClass:"text_xs"},[_vm._v(" Create New Label ")])])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }