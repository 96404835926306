/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
export const TEMPLATES = [
  {
    name: 'Personal',
    _id: 'personal',
    data: [
      {
        name: 'First Name',
        value: 'first_name',
        space: 10,
        amount: 0,
      },
      {
        name: 'Total years in career',
        value: 'total_years_in_career',
        space: 4,
        amount: 0,
      },
      {
        name: 'Last Name',
        value: 'last_name',
        space: 15,
        amount: 0,
      },
      {
        name: 'Total career positions count',
        value: 'total_career_positions_count',
        space: 4,
        amount: 0,
      },
      {
        name: 'Occupation',
        value: 'occupation',
        space: 20,
        amount: 0,
      },
      {
        name: 'College name',
        value: 'college_name',
        space: 15,
        amount: 0,
      },
    ],
  },
  {
    name: 'Company & Location',
    _id: 'company',
    data: [
      {
        name: 'Location',
        value: 'location',
        space: 15,
        amount: 0,
      },
      {
        name: 'Company name',
        value: 'company_name',
        space: 15,
        amount: 0,
      },
      {
        name: 'Years in company',
        value: 'years_in_company',
        space: 4,
        amount: 0,
      },
    ],
  },
  {
    name: 'Custom',
    _id: 'custom',
    data: [
      {
        name: 'Custom field 1',
        value: 'custom_field_1',
        space: 30,
        amount: 0,
      },
      {
        name: 'Custom field 2',
        value: 'custom_field_2',
        space: 30,
        amount: 0,
      },
      {
        name: 'Custom field 3',
        value: 'custom_field_3',
        space: 30,
        amount: 0,
      },
    ],
  },
  {
    name: 'Lyne',
    _id: 'lyne',
    data: [
      {
        name: 'First Name',
        value: 'lyne_statement',
        text: 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
        space: 40,
        amount: 0,
      },
      {
        name: 'Last Name',
        value: 'lyne_question?',
        text: 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
        space: 15,
        amount: 0,
      },
      {
        name: 'Total career positions count',
        value: 'lyne_followup',
        text: 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
        space: 50,
        amount: 0,
      },
    ],
  },
];
