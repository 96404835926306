/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { getSpace } from '@/api/spaceMethods';
import { getSpaces } from '@/api/userMethods';
import { checkAdmin } from '@/api/AdminMethods.js';
import { brandsNoEmailForBasic } from '@/utils/brandsConfigs';

const state = () => ({
  account: null,
  isAdmin: false,
  isEmail: true,
  spacesList: [],
  space: undefined,
});

const getters = {
  account: state => {
    return state.account;
  },
  space: state => {
    return state.space;
  },
  isAdmin: state => {
    return state.isAdmin;
  },
  isEmail: state => {
    return state.isEmail;
  },
  spacesList: state => {
    return state.spacesList;
  },
};

const mutations = {
  SET_ACCOUNT (state, payload) {
    state.account = payload;
  },
  // SET_SUBSCRIPT_ACCOUNT (state, payload) {
  //   state.account.subscription = payload;
  //   state.account.is_paid = true;
  // },
  SET_IS_ADMIN (state, payload) {
    state.isAdmin = payload;
  },
  SET_IS_EMAIL (state, payload) {
    state.isEmail = payload;
  },
  SET_SPACE (state, payload) {
    state.space = payload;
  },
  SET_SPACES_LIST (state, payload) {
    state.spacesList = payload;
  },
  UPDATE_CREDITS (state, credits) {
    state.account.credits = credits;
  },
  DELETE_SPACE (state, payload) {
    // const spacesList = state.spacesList.slice(0);
    if (state.account._id === payload._id) {
      state.account = state.spacesList[0];
    }
    state.spacesList = state.spacesList.filter(space => space._id !== payload._id);
  },
  UPDATE_SPACES_LIST (state, payload) {
    const spacesList = state.spacesList.slice(0);
    const existingIndex = spacesList.findIndex(space => space._id === payload._id);
    if (state.account?._id === payload._id) {
      state.account = payload;
    }
    if (existingIndex > -1) {
      spacesList.splice(existingIndex, 1, payload);
    } else {
      spacesList.push(payload);
    }
    state.spacesList = spacesList;
  },
};

const actions = {
  async SET_ACCOUNT ({ commit }, payload) {
    const space = payload || await getSpace();

    if (brandsNoEmailForBasic.includes(space.config.brand)) {
      commit('SET_IS_EMAIL', space?.subscription.tier !== 'basic');
    }

    commit('SET_ACCOUNT', space);
  },
  SET_SPACE ({ commit }, payload) {
    commit('SET_SPACE', payload);
  },
  async SET_IS_ADMIN ({ commit }, payload) {
    const isAdmin = await checkAdmin();
    commit('SET_IS_ADMIN', isAdmin);
  },
  async SET_SPACES_LIST ({ commit }, payload) {
    const spaces = payload || await getSpaces();
    commit('SET_SPACES_LIST', spaces);
    return spaces;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
