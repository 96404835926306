<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="constructor-switcher">
    <button
      :class="buttonClasses(isEditMode)"
      @click="changeMode"
    >
      <span class="text_s text_weight_accent">Edit</span>
    </button>
    <button
      v-if="$route.name !== 'add-campaign'"
      :class="buttonClasses(!isEditMode)"
      @click="changeMode"
    >
      <span class="text_s text_weight_accent">Preview</span>
    </button>
  </div>
</template>
<script>
  export default {
    props: {
      isEditMode: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      buttonClasses () {
        return (isActive) => [
          'constructor-switcher__button',
          'constructor-switcher__button_' + (isActive ? 'active' : 'inactive'),
        ];
      },
    },
    methods: {
      changeMode () {
        if (this.$route.name !== 'add-campaign') {
          return this.$emit('change');
        }
      },
    },
  };
</script>
<style lang="scss">
.constructor-switcher {
  @include flex-row;
  gap: 4px;
  padding: 4px;
  border-radius: $border-radius-big;
  background: $cl-neutral-100;

  &__button {
    @include default-button;
    gap: 4px;
    width: 100%;
    height: 100%;
    border-radius: $border-radius-small;

    &_active,
    &_inactive:hover {
      background: $cl-white;
    }

    &_inactive {
      color: $t-light-tertiary;
    }
  }
}
</style>
