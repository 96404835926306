<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="connection-request">
    <div class="flex-row left connection-request__switchers flex-gap-8">
      <div class="connection-request__switcher">
        <div class="flex-gap-4 flex">
          <default-switcher
            size="s"
            :is-checked="isTextIncludes"
            @change="isTextIncludes = !isTextIncludes"
          />
          <span
            class="text_s"
            :class="{'color_secondary': !isTextIncludes}"
          >Include text message</span>
        </div>
        <span
          v-if="isTextIncludes"
          class="text_s color_secondary"
        >{{ `${reservedSpace}/300` }}</span>
      </div>
    </div>
    <div class="connection-request__error">
      <span
        v-for="(error, key) in messageError"
        :key="key"
        class="text_xs"
        :class="key === 'warningText' ? 'color_warning' : 'color_danger'"
      >
        <i
          v-if="error"
          class="bx bx-info-circle"
        />
        {{ error }}
      </span>
    </div>
    <div
      class="connection-request__textarea-container"
      @mouseover="showTemplateMenu"
      @mouseleave="isShowTemplateMenu = false"
    >
      <textarea
        ref="messageArea"
        v-model="text"
        :readonly="readOnly"
        placeholder="Type your message"
        class="connection-request__textarea"
        :disabled="!isTextIncludes"
        @keydown="handlerKeydown"
      />
      <template-unlink-menu
        v-show="isShowTemplateMenu"
        class="connection-request__template-menu"
        :class="{'connection-request__template-menu_active': isShowTemplateMenu}"
        :template="template"
        @unlink-template="unlinkTemplate"
        @edit-template="editTemplate"
      />
    </div>
    <div class="connection-request__switcher connection-request__switcher_down">
      <default-switcher
        size="s"
        class="left"
        :is-checked="isWaitAccept"
        @change="$emit('update:is-wait-accept', !isWaitAccept)"
      />
      <span
        class="text_s"
        :class="{'color_secondary': !isWaitAccept}"
      >Wait for connection request to be accepted
      </span>
    </div>
    <step-menu
      :brand="brand"
      :disabled="!isTextIncludes"
      :video="video"
      :template="template"
      :message="text"
      type="li_connect"
      @add-practice="addPractice"
      @add-template-to-area="addTemplate"
      @remove-video="removeVideo"
      @insert-video="insertVideo"
      @unlink-template="unlinkTemplate"
      @choose-video="$emit('choose-video')"
      @choose-template="$emit('choose-template')"
    />
  </div>
</template>
<script>
  import { addTemplateMixin } from '@/mixins/addTemplateMixin.js';
  import DefaultSwitcher from '@/components/base/uiKit/DefaultSwitcher.vue';
  import { templatesMixin } from '@/mixins/templatesMixin.js';
  import StepMenu from '@/components/updateWorkflow/performAction/menuFiles/StepMenu';
  import TemplateUnlinkMenu from '@/components/updateWorkflow/performAction/menuFiles/TemplateUnlinkMenu';
  // import { TEMPLATES } from '@/utils/workflows/templates';

  export default {
    name: 'ConnectionRequest',
    components: {
      TemplateUnlinkMenu,
      StepMenu,
      DefaultSwitcher,
    },
    mixins: [addTemplateMixin, templatesMixin],
    props: {
      brand: {
        type: String,
        default: '',
      },
      isWaitAccept: {
        type: Boolean,
        default: true,
      },
      message: {
        type: String,
        default: '',
      },
      status: {
        type: Boolean,
        default: false,
      },
      messageError: {
        type: Object,
        default: () => ({
          warningText: '',
          errorText: '',
        }),
      },
      video: {
        type: Object,
        default: null,
      },
      template: {
        type: Object,
        default: null,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      messageLength: {
        type: Number,
        default: 0,
      },
    },
    data () {
      return {
        text: this.message,
        isTextIncludes: false,
        templateSpace: [],
        isShowTemplateMenu: false,
        // templates: TEMPLATES.slice().flatMap(section => section.data),
      };
    },
    computed: {
      templateLength () {
        return this.templatesRegexps.reduce((prev, r) => {
          const tempName = this.text.match(new RegExp(r, 'g'));
          if (tempName) {
            prev[tempName[0]] = tempName.length;
          }
          return prev;
        }, {});
      },
      reservedSpace () {
        const templates = this.onlyTemplates;
        const diff = templates.reduce((sum, t) => sum + (t?.space - `{${t?.value}}`.length) * t?.amount, 0) || 0;
        return diff + this.text.length;
      },
    },
    watch: {
      message () {
        this.text = this.message;
        if (!this.status && this.message.length > 0) {
          this.$emit('update:status', true);
        }
      },
      text () {
        this.calculateLength();
        this.$emit('update:message', this.text, this.reservedSpace);
        this.$emit('update:message-length', this.reservedSpace);
      },
      template () {
        if (this.template?.text_template) {
          this.text = this.template.text_template;
        }
      },
      isTextIncludes () {
        if (!this.isTextIncludes) {
          this.text = '';
        }
      },
    },
    created () {
      this.calculateLength();
      if (this.message) {
        this.$emit('update:status', true);
      }
    },
    mounted () {
      if (this.message) {
        this.isTextIncludes = true;
      }
    },
    methods: {
      handleStatusChange () {
        if (this.status) {
          this.text = '';
        }
        this.$emit('update:status', !this.status);
      },
      showTemplateMenu () {
        if (this.readOnly) {
          this.isShowTemplateMenu = true;
        }
      },
      addTemplate (template, space) {
        if (this.reservedSpace + space <= 300) {
          this.text = this.addTemplateToArea(this.text, template);
        }
      },
      unlinkTemplate () {
        this.isShowTemplateMenu = false;
        this.$emit('unlink-template');
      },
      editTemplate (template) {
        this.isShowTemplateMenu = false;
        this.$emit('edit-template', template);
      },
      calculateLength () {
        this.onlyTemplates.forEach(e => {
          e.amount = this.templateLength[`{${e.value}}`] || 0;
        });
      },
      handlerKeydown (event) {
        if (this.reservedSpace >= 300 && event.key !== 'Backspace' && event.key !== 'Delete') {
          event.preventDefault();
        }
      },
      insertVideo (video) {
        this.$emit('insert-video', video);
      },
      removeVideo (video) {
        this.$emit('remove-video', video);
      },
      addPractice (item) {
        this.text = item.message;
      },
    },
  };
</script>
<style lang="scss">
.connection-request {
  &__switchers{
    padding: 12px 16px;

  }
  &__switcher {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    &_down {
      padding: 16px;
    }
  }
  &__error {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 0px 16px;
  }
  &__template-menu {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    transition: 0.3s;
    &_active {
      opacity: 1;
    }
  }
  &__textarea-container {
    position: relative;
  }
  &__textarea {
    resize: none;
    width: 100%;
    border: none;
    padding: 8px 16px;
    min-height: 160px;
    background: var(--neutral-50);
    border-top: 1px solid rgba(10, 32, 51, 0.06);
  }
}
</style>
