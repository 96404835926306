<template>
  <div class="flex-column flex-gap-16 pb-25 pt-25 pl-2 pr-2">
    <h1 class="text_s text_weight_accent">
      Work experience
    </h1>
    <div
      v-if="profile.experience.length"
      class="flex-column flex-gap-16"
    >
      <item-experience
        v-for="(item, i) in profile.experience"
        :key="i"
        :experience="item"
      />
    </div>
    <div
      v-else
      class="flex-column-centered flex-gap-16"
    >
      <i class="bx bx-buildings color_tertiary icon_size_24" />
      <span class="color_tertiary text_s">
        No Work Experience
      </span>
    </div>
  </div>
</template>
<script>
  import ItemExperience from '@/components/inboxPage/info/experience/ItemExperience';
  export default {
    name: 'ExperienceInfo',
    components: {
      ItemExperience,
    },
    props: {
      profile: {
        type: Object,
        required: true,
      },
    },
  };
</script>
