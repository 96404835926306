const regexps = {
  // разрешенные пути (не нужно редиректить)
  credentialsAdd: [
    /credentials-add/,
    /billing/,
    /workspace(.)*/,
    /accept-invite/,
    /pricing(.)*/,
    /onboarding(.)*/,
    /user(.)*/,
    /privacy-policy/,
    /terms-conditions/,
    /video/,
    /crm/,
  ],
  pricing: [
    /workspace(.)*/,
    /accept-invite/,
    /user(.)*/,
    /onboarding(.)*/,
    /pricing(.)*/,
    /crm/,
  ],
  // запрещенные пути (откуда нужно редиректнуть)
  billing: [
    /billing(.)*/,
    /prepaid/,
  ],
  campaigns: [
    /auth\//,
    /\/space\//,
  ],
};

class RedirectRule {
  /**
     * @param {{name: string}} redirectName
     * @param {boolean} [allowed=true]
     * @param {RegExp[]} regexps
     */
  constructor (redirectName, regexps, allowed = true) {
    this.defaultRedirect = { name: redirectName };
    this.allowed = allowed;
    this.regexps = regexps;
  }

  /** @param {string} path */
  check (path, isAdmin) {
    let { regexps, allowed } = this;
    if (isAdmin && allowed) {
      regexps = [...regexps, /admin(.)*/];
    }
    const isIncluded = regexps.some((regex) => regex.test(path));
    return allowed ? isIncluded : !isIncluded;
  }
}

const redirectRules = {
  credentialsAdd: new RedirectRule('credentials-add', regexps.credentialsAdd),
  pricing: new RedirectRule('pricing', regexps.pricing),
  billing: new RedirectRule('billing', regexps.billing, false),
  campaigns: new RedirectRule('campaigns', regexps.campaigns, false),
};
export { RedirectRule, redirectRules };
