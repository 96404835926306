<template>
  <default-tooltip-block
    data-closable="selectReplyer"
    list-position="left"
  >
    <template #default>
      <div
        class="flex-row-between flex-gap-4 cursor-pointer"
      >
        <div
          v-if="selected?.email"
          class="flex-row flex-gap-8"
        >
          <component
            :is="selected?.icon"
            class="select-replyer__image"
          />
          <p
            class="text_xs"
          >
            {{ selected?.email }}
          </p>
        </div>
        <p
          v-else
          class="text_xs"
        >
          Email isn't selected
        </p>
        <i class="bx bx-chevron-down icon_size_16" />
      </div>
    </template>
    <template #body>
      <ul>
        <li
          v-for="item in items"
          :key="item.value"
          class="select-replyer__item"
          @click="$emit('select', item)"
        >
          <component
            :is="item.icon"
            class="select-replyer__image"
          />
          <p class="text_xs">
            {{ item.email }}
          </p>
        </li>
        <li
          v-if="!items.length"
          class="select-replyer__item"
        >
          <p class="text_xs">
            No emails
          </p>
        </li>
      </ul>
    </template>
  </default-tooltip-block>
</template>
<script>
  import DefaultTooltipBlock from '@/components/base/uiKit/DefaultTooltipBlock';

  import {
    MailIcon
  } from 'vue-feather-icons';
  import GmailIcon from '@/assets/icons/GmailIcon';
  import OutlookIcon from '@/assets/icons/OutlookIcon';
  import SendGridIcon from '@/assets/icons/SendGridIcon';
  import ExchangeIcon from '@/assets/icons/ExchangeIcon';
  import AwsIcon from '@/assets/icons/AwsIcon';

  export default {
    name: 'SelectEmails',
    components: {
      DefaultTooltipBlock,
      MailIcon,
      GmailIcon,
      OutlookIcon,
      SendGridIcon,
      ExchangeIcon,
      AwsIcon,
    },
    props: {
      selected: {
        type: Object,
        default: () => {},
      },
      items: {
        type: Array,
        required: true,
      },
    },
  };
</script>
<style lang="scss">
.select-replyer {
  &__image {
    display: block;
    width: 16px;
    height: 16px;
  }

  &__item {
    @include flex-row;
    gap: 8px;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: var(--active-bg-cl);
    }
  }
}
</style>
