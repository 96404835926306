<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="row end">
    <div class="row">
      <div>
        <input
          id="videoInput"
          class="video-editor__input"
          type="file"
          accept="video/*"
          @change="$emit('set-video', $event)"
        >
        <label
          for="videoInput"
          class="primary-btn_gray primary-btn_icon primary-btn_size_s"
        >
          <i class="bx bx-upload" />
          Upload
        </label>
      </div>
      <button
        :disabled="!mediaRecorder"
        class="primary-btn_blue primary-btn_icon primary-btn_size_s"
        @click="$emit('start-video')"
      >
        <i class="bx bx-video-recording" />
        Start Recording
      </button>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      mediaRecorder: {
        type: MediaRecorder,
        default: null,
      },
    },
  };
</script>
<style lang="scss" scoped>
.video-editor__input {
  display: none !important;
}
</style>
