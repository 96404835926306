<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    class="crm-chosen-item"
  >
    <div class="crm-chosen-item__prospect-name">
      <span class="crm-chosen-item__text text_s">{{ company?.name }}</span>
      <span
        class="crm-chosen-item__text crm-chosen-item__text_delete crm-chosen-item__text_margin-left color_danger text_m"
        @click="deleteItem(company, true)"
      ><i class="bx bx-x" /></span>
    </div>
    <div class="crm-chosen-item__prospect-position">
      <span class="crm-chosen-item__text text_xs color_secondary"><i class="bx bx-globe" />Domain</span>
      <span
        :class="{'crm-chosen-item__text_link': company?.domain}"
        class="crm-chosen-item__text color_secondary text_xs"
      >{{ company?.domain || 'No Domain' }}</span>
    </div>
  </div>
</template>

<script>
  import { setAltImg } from '@/mixins/setAltImg';
  import { mapGetters } from 'vuex';

  export default {
    name: 'CrmChosenCompanyItem',
    components: {
    },
    mixins: [setAltImg],
    props: {
      company: {
        type: Object,
        default: () => {},
      },
      prospect: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {

      };
    },

    computed: {
      ...mapGetters({
        credentialsList: 'credentials/credentialsList',
      }),
      existProspectId () {
        if (['default', 'pipedrive', 'hubspot'].includes(this.prospect.platform)) {
          return this.prospect.local_profile_id || '';
        } else return this.prospect._id;
      },
    },
    watch: {

    },
    created () {
    },
    mounted () {
    },
    methods: {
      openProfileWithInbox () {
        if (this.existProspectId) {
          const credWithProfile = this.credentialsList.find(cred => cred._id === this.prospect?.local_credentials || cred._id === this.prospect._id);
          const routeData = this.$router.resolve({ path: `/inbox/${this.existProspectId}`, query: { selectedCred: `${credWithProfile._id}` } });
          window.open(routeData.href, '_blank');
        }
      },
      deleteItem (item, isCompanies) {
        this.$emit('delete-item', item, isCompanies);
      },
    },
  };
</script>

<style lang="scss">
.crm-chosen-item {
  box-shadow: 0px 2px 4px rgba(10, 32, 51, 0.06), 0px 0px 8px rgba(10, 32, 51, 0.05);
  border-radius: 8px;
  display: flex;
  flex-flow: column;
  gap: 16px;
  padding: 8px;
  &_pointer {
    cursor: pointer;
  }
  &:hover {
    .crm-chosen-item__text_delete,  .crm-chosen-item__text_open-inbox {
      opacity: 1;
    }
  }
  &__details, &__prospects, &__companies {
    display: flex;
    flex-flow: column;
    flex: 1;
    gap: 8px;
  }
  &__prospect-footer {
    display: flex;
    align-items: flex-end;
  }
  &__prospects-header, &__companies-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &__prospect-name, &__company-name {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  &__prospect-position {
    display: flex;
    flex-flow: column;
  }
  &__image {
    border-radius: 100%;
    width: 16px;
    height: 16px;
  }
  &__inputs-block {
    width: 100%;
    display: flex;
    flex-flow: wrap;
    row-gap: 12px;
    align-items: flex-end;
  }
  &__input-item {
    width: 100%;
    &_half {
      width: 50%;
    }
  }
  &__text {
    display: flex;
    align-items: center;
    gap: 4px;
    &_link {
      color: var(--primary-cl);
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    &_margin-left {
      margin-left: auto;
    }
    &_delete, &_open-inbox {
      cursor: pointer;
      transition: 100ms;
      opacity: 0;
    }
  }
}
</style>
