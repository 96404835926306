<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <portal to="body-end-2">
    <modal
      :key="key"
      name="CrmAddItemModal"
      width="836px"
      height="auto"
      :adaptive="true"
      @closed="handleClose"
    >
      <div
        ref="vldParent"
        class="modal crm-add-item-modal vld-parent"
      >
        <div
          class=" crm-add-item-modal__close-button"
          @click="handleClose"
        >
          <i class="bx bx-x" />
        </div>
        <div
          class="crm-add-item-modal__main"
        >
          <div class="crm-add-item-modal__header">
            <div class="crm-add-item-modal__select-crm">
              <div
                class="crm-add-item-modal__selected-crm"
                @click="isSelectCrm = !isSelectCrm"
              >
                <img loading="lazy"
                  :src="require(`@/assets/integrationCRM/${chosenCrm}.svg`)"
                  alt="logo pipedrive"
                >
                <i
                  class="crm-add-item-modal__selected-crm-arrow bx bxs-down-arrow"
                  :class="{'crm-add-item-modal__selected-crm-arrow_open': isSelectCrm}"
                />
              </div>
              <ul
                class="crm-add-item-modal__select-items"
                :class="{'crm-add-item-modal__select-items_open': isSelectCrm,
                         'crm-add-item-modal__select-items_close': !isSelectCrm,
                }"
              >
                <li
                  v-for="(crmItem, i) in crms"
                  :key="i"
                  class="crm-add-item-modal__select-item"
                  @click.stop="selectCrm(crmItem)"
                >
                  <img loading="lazy"
                    :src="require(`@/assets/integrationCRM/${crmItem}.svg`)"
                    :alt="`logo ${crmItem}`"
                  >
                </li>
              </ul>
            </div>
            <default-input
              :value.sync="query"
              placeholder="Search..."
              :debounce="300"
              :is-fulled="true"
              size="m"
              icon="bx-search"
              @input="search"
            />
          </div>
          <div class="crm-add-item-modal__items">
            <template v-if="items?.length">
              <div
                v-for="item in items"
                :key="item._id"
                class="crm-add-item-modal__item"
              >
                <crm-prospect-item
                  :item="item"
                  :is-companies="isCompanies"
                  :chosen-prospects="chosenProspects.map(e => e._id) || []"
                  :chosen-companies="chosenCompanies.map(e => e._id) || []"
                  :crm="crm"
                  @select-item="selectItemsByFlag"
                />
              </div>
            </template>
            <span
              v-if="(loaded && !items?.length)"
              class="crm-add-item-modal__text color_secondary text_m"
            >There are no {{ isCompanies ? 'companies' : 'prospects' }}</span>
          </div>
          <div class="crm-add-item-modal__buttons-block">
            <default-button
              size="m"
              :title="isCompanies ? 'Add Companies' : 'Add Prospects'"
              color="primary"
              form="rectangle"
              @action="handleClose"
            />
          </div>
        </div>
      </div>
    </modal>
  </portal>
</template>

<script>
  import { loaderMixin } from '@/mixins/loaderMixin';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import { getProfilesIntegration, getProfilesCompanies } from '@/api/crmIntegration';
  import { getProfiles } from '@/api/profilesMethods';
  import DefaultInput from '@/components/base/uiKit/DefaultInput';
  import CrmProspectItem from '@/modals/CrmModals/CrmProspectItem';
  import { mapGetters } from 'vuex';

  export default {
    name: 'CrmAddItemModal',
    components: {
      DefaultInput,
      DefaultButton,
      CrmProspectItem,
    },
    mixins: [loaderMixin],
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      chosenCompanies: {
        type: Array,
        default: () => [],
      },
      chosenProspects: {
        type: Array,
        default: () => [],
      },
      isCompanies: {
        type: Boolean,
        default: false,
      },
      crm: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        key: 'CrmAddItemModal',
        inputs: {},
        isSelectCrm: false,
        items: [],
        query: '',
        chosenCrm: this.crm,
      };
    },
    computed: {
      ...mapGetters({
        account: 'account/account',
        selectedCredential: 'credentials/selectedCredential',
      }),
      crms () {
        let crm = [];
        if (this.crm === 'default') {
          crm = ['default'];
        };
        if (this.crm === 'hubspot') {
          crm = ['default', 'hubspot'];
        };
        if (this.crm === 'pipedrive') {
          crm = ['default', 'pipedrive'];
        };
        return crm.filter(e => e !== this.chosenCrm);
      },
    },
    watch: {
      async show (show) {
        if (show) {
          this.isSelectCrm = false;
          this.chosenCrm = this.crm;
          this.$modal.show('CrmAddItemModal');
          await this.search();
        } else {
          this.$modal.hide('CrmAddItemModal');
          this.query = '';
          this.key = `${this.key} ${new Date().getTime()}`;
        }
      },
    },
    async created () {

    },
    mounted () {
    },
    methods: {
      handleClose () {
        this.$emit('update:show', false);
      },
      async search () {
        try {
          setTimeout(() => {
            this.loaded = false;
          });
          if (this.isCompanies) {
            this.items = await getProfilesCompanies(this.chosenCrm, this.query);
          } else if (!this.isCompanies && (this.crm === 'default' || this.chosenCrm === 'default')) {
            const filters = { workflows: [] };
            const items = await getProfiles(filters, this.selectedCredential._id, this.query, 0, 8);
            this.items = items.profiles;
          } else if (!this.isCompanies && this.crm !== 'default') {
            this.items = await getProfilesIntegration(this.chosenCrm, this.query);
          }
          if (this.items && this.items.length) {
            this.items.forEach(element => {
              if (element.external_id) {
                element = Object.assign(element, {});
                element._id = element.external_id;
              }
            });
          }
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
      selectItem (item, items, update) {
        const itemsIds = items.map(e => e._id) || [];
        if (itemsIds.includes(item._id)) {
          items = items.filter(e => e._id !== item._id);
        } else {
          if (this.crm !== 'pipedrive' ||
            (!this.isCompanies && this.crm === 'pipedrive' && this.chosenProspects <= 0) ||
            (this.isCompanies && this.crm === 'pipedrive' && this.chosenCompanies <= 0)) {
            items.push(item);
          }
        }
        this.$emit(update, items);
      },
      selectItemsByFlag (item) {
        let items;
        let emit;
        if (this.isCompanies) {
          items = [...this.chosenCompanies];
          emit = 'update:chosenCompanies';
        } else {
          items = [...this.chosenProspects];
          emit = 'update:chosenProspects';
        }
        this.selectItem(item, items, emit);
      },
      async selectCrm (crm) {
        this.isSelectCrm = false;
        this.chosenCrm = crm;
        await this.search();
      },
    },
  };
</script>

<style lang="scss">
.crm-add-item-modal {
  transition: 0.8s;
  height: 100%;
  position: relative;
  background-color: var(--background-color);
  padding: 24px;
  border-radius: 8px;
  &__close-button {
    position: absolute;
    cursor: pointer;
    display: block;
    padding: 0;
    margin: 0;
    top: 8px;
    right: 8px;
    border: none;
    outline: none;
    background: none;
    font-size: 18px;
    color: #919399;
  }
  &__main {
    display: flex;
    flex-flow: column;
    gap: 24px;
  }
  &__header {
    display: flex;
    gap: 8px;
  }
   &__select-crm {
      position: relative;
      display: flex;
      flex-flow: column;
      transition: 300ms;
    }
    &__selected-crm {
      z-index: 99;
      padding: 3px 4px 0px 4px;
      position: relative;
      cursor: pointer;
      &-arrow {
        position: absolute;
        top: 15px;
        right: 16px;
        font-size: 8px;
        color: #fff;
        z-index: 101;
        transition: 400ms;
        &_open {
          transform: rotate(180deg);
        }
      }
    }
    &__select-items {
      position: absolute;
      padding: 0px 4px;
      background: var(--background-color);
      border-radius: 8px;
      box-shadow: 0px 2px 4px rgba(10, 32, 51, 0.06), 0px 0px 8px rgba(10, 32, 51, 0.05);
      transition: 300ms;
      &_open {
        top: 40px;
        opacity: 1;
        z-index: 9;
      }
      &_close {
        top: 0px;
        opacity: 0;
        z-index: 0;
      }
    }
    &__select-item {
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    &__buttons-block {
      display: flex;
      justify-content: flex-end;
    }
     &__items {
      display: flex;
      flex-flow: column;
      gap: 8px;
      max-height: 360px;
      padding: 4px;
      overflow-y: auto;
      overflow-x: hidden;
    }
    &__text {
      display: flex;
      align-items: center;
      gap: 4px;
    }
}
</style>
