<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <portal to="body-end">
    <modal
      key="SavedResponsesModal"
      name="SavedResponsesModal"
      width="900px"
      height="auto"
      classes="component"
      :adaptive="true"
      @closed="handleClose"
    >
      <div class="modal reply-modal">
        <div
          class="reply-modal__close-button"
          @click="handleClose"
        >
          <i class="bx bx-x" />
        </div>
        <h2 class="reply-modal__title">
          Add Saved Reply
        </h2>
        <div class="reply-modal__main">
          <section class="modal__input">
            <input
              ref="name"
              v-model="name"
              type="text"
              :class="['input', { input_error: error.name }]"
              placeholder="Title"
              @focus="isName = true"
            >
            <p
              v-if="error.name"
              class="modal__error"
            >
              This field is required
            </p>
          </section>
          <section class="modal__input reply-modal__textTemplate">
            <textarea
              ref="messageArea"
              v-model="textTemplate"
              class="reply-modal__textarea"
              :class="['input', { input_error: error.textTemplate }]"
              placeholder="Text"
              @focus="isName = false"
            />
            <p
              v-if="error.textTemplate && !textTemplate"
              class="modal__error"
            >
              This field is required
            </p>
          </section>
          <step-menu
            :brand="account.brand"
            :message="textTemplate"
            @add-template-to-area="addTemplate"
          />
          <div class="reply-modal__buttons">
            <default-button
              size="m"
              title="Cancel"
              color="neutral"
              form="rectangle"
              @action="handleClose"
            />
            <default-button
              size="m"
              :title="primaryText"
              color="primary"
              form="rectangle"
              @action="send"
            />
          </div>
        </div>
      </div>
    </modal>
  </portal>
</template>

<script>
  import { XIcon } from 'vue-feather-icons';
  import StepMenu from '@/components/updateWorkflow/performAction/menuFiles/StepMenu';
  import { addTemplateMixin } from '@/mixins/addTemplateMixin.js';
  import { mapGetters } from 'vuex';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';

  // TODO rewrite using default modal
  export default {
    name: 'SavedResponsesModal',
    components: {
      DefaultButton,
      XIcon,
      StepMenu,
    },
    mixins: [addTemplateMixin],
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: 'Add reply',
      },
      primaryText: {
        type: String,
        default: 'Add Saved Reply',
      },
      response: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        name: this.response.name,
        textTemplate: this.response?.text_template || '',
        showcreate: false,
        error: {
          name: false,
          textTemplate: false,
        },
        isName: false,
      };
    },
    computed: {
      ...mapGetters({
        account: 'account/account',
      }),
    },
    watch: {
      show (show) {
        show
          ? this.$modal.show('SavedResponsesModal')
          : this.$modal.hide('SavedResponsesModal');
      },
      response () {
        this.name = this.response?.name || '';
        this.textTemplate = this.response?.text_template || '';
      },
    },
    methods: {
      handleClose () {
        this.$emit('update:show', false);
      },
      handleTextError (error) {
        this.error.textTemplate = error;
      },
      addTemplate (template) {
        if (this.isName) {
          this.name = this.addTemplateToArea(this.name, template, 'name');
        } else this.textTemplate = this.addTemplateToArea(this.textTemplate, template);
      },
      send () {
        this.error.name = !this.name;
        this.error.textTemplate = !this.textTemplate;
        if (!this.error.textTemplate && !this.error.name) {
          this.$emit('send', this.name, this.textTemplate, this.response._id);
          this.name = '';
          this.textTemplate = '';
          this.handleClose();
        }
      },
    },
  };
</script>

<style lang="scss">

  .reply-modal {
    transition: 0.8s;
    height: 100%;
    position: relative;
    background-color: var(--background-color);
    padding: 24px;
    border-radius: 8px;
    display: flex;
    flex-flow: column;
    gap: 8px;

    .vue-slider.vue-slider-ltr {
      height: 6px !important;
    }

    .vue-slider-dot-handle, .vue-slider-dot-handle-focus {
      box-shadow: 0px 2px 4px rgba(10, 32, 51, 0.06), 0px 0px 8px rgba(10, 32, 51, 0.05);
    }

    &__close-button {
      position: absolute;
      cursor: pointer;
      display: block;
      padding: 0;
      margin: 0;
      top: 12px;
      right: 12px;
      border: none;
      outline: none;
      background: none;
      font-size: 24px;
      color: #919399;
    }

    &__main {
      display: flex;
      flex-flow: column;
      gap: 16px;
    }

    &__textarea {
      min-height: 200px;
      resize: none;
    }
    &__buttons {
      display: flex;
      gap: 8px;
      margin-left: auto;
    }
  }
</style>
