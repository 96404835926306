/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
export const CRM_DEAL_INPUTS = [
  {
    _id: 'platform',
    size: 'full',
    chosen: {},
    error: false,
    validation: '',
    name: 'Platform',
    placeholder: 'Select platform',
    data: [],
  },
  {
    _id: 'title',
    input: true,
    size: 'full',
    model: '',
    name: 'Name',
    validation: 'required',
    placeholder: 'Add Name',
  },
  {
    _id: 'pipeline_id',
    size: 'full',
    chosen: {},
    error: false,
    validation: '',
    name: 'Pipeline',
    placeholder: 'Select Pipeline',
    data: [],
  },

  {
    _id: 'stage_id',
    size: 'full',
    chosen: {},
    error: false,
    name: 'Stage',
    validation: '',
    placeholder: 'Select Stage',
    data: [],
  },
  {
    _id: 'value',
    input: true,
    size: 'half',
    model: '',
    validation: 'integer',
    name: 'Deal Amount',
    placeholder: 'Amount',
  },
  {
    _id: 'currency',
    size: 'half',
    chosen: {},
    error: false,
    name: ' ',
    placeholder: 'Currency',
    data: [],
  },
  {
    _id: 'owner_id',
    size: 'full',
    chosen: {},
    error: false,
    name: 'Owner',
    placeholder: 'Choose Owner',
    data: [
      { name: 'All Owners', _id: 'all' },
      { name: 'No Owner', _id: 'none' },
    ],
  },
  {
    _id: 'probability',
    input: true,
    size: 'half',
    model: '',
    name: 'Probability',
    validation: 'min_value:0|max_value:100|integer',
    placeholder: 'Probability',
  },
];
