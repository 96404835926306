/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { mapGetters } from 'vuex';
const moment = require('moment');
export const fromNowFull = (date) => {
  return moment
    .utc(date)
    .startOf('minutes')
    .fromNow(true);
};
export const fromNowFilterMixin = {
  computed: {
    ...mapGetters({
      selectedCredential: 'credentials/selectedCredential',
    }),
  },
  filters: {
    fromNowFull,
  },
  methods: {
    getFromNowUTC (date, fullDateParts = false) {
      const fullDate = moment
        .utc(date)
        .startOf('minutes')
        .fromNow(true);
      const dateParts = fullDate.split(' ');
      if (fullDateParts) {
        return fullDate;
      }
      let datePart = dateParts[1].slice(0, 3);

      switch (datePart) {
        case 'few':
          datePart = 'just now';
          break;
        case 'hou':
          datePart = 'h';
          break;
        case 'day':
          datePart = 'd';
          break;
        case 'mon':
          datePart = 'mo';
          break;
        case 'min':
          datePart = 'm';
          break;
      }
      if (datePart === 'just now') {
        return datePart;
      }
      return (
        (['a', 'an'].includes(dateParts[0]) ? '1' : dateParts[0]) + datePart
      );
    },
    fromNowFull,
  },
};
