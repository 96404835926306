/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { updateUser } from '@/api/userMethods';
import { useStore } from '@/store';
import { computed, ref, watch } from 'vue';
import { isEqual } from 'lodash';
export const useRecentEmails = () => {
  const store = useStore();
  const user = computed(() => store.getters['user/user']);
  const recentlyUsedEmails = ref(user.value.recently_used_emails.slice());
  watch(recentlyUsedEmails, async () => {
    if (
      !isEqual(recentlyUsedEmails.value, user.value.recently_used_emails)
    ) {
      const response = await updateUser({ ...user.value, recently_used_emails: recentlyUsedEmails });
      await store.dispatch('user/SET_USER', response);
    }
  }, { deep: true });
  return { recentlyUsedEmails };
};
