<template>
  <div
    :class="[
      'flex-column flex-gap-16 pb-25',
    ]"
  >
    <map-basic
      class="basic-info__map"
      :profile="profile"
    />
    <body-basic
      :profile="profile"
      class="pl-2 pr-2"
      @update-profile="$emit('update-profile', $event)"
      @update-profile-prop="$emit('update-profile-prop', $event)"
    />
  </div>
</template>
<script>
  import MapBasic from '@/components/inboxPage/info/basic/MapBasic';
  import BodyBasic from '@/components/inboxPage/info/basic/BodyBasic';

  export default {
    name: 'BasicInfo',
    components: {
      MapBasic,
      BodyBasic,
    },
    props: {
      profile: {
        type: Object,
        required: true,
      },
    },
  };
</script>
